import { RefObject, useEffect } from 'react';

/** Determines if a target DOM node is a child of ref */
const refContainsTarget = (ref: RefObject<Node>, target: Event['target']): boolean => {
  return !!ref.current?.contains(target as Node);
};

/**
 * Call a callback function if focus shifts outside of a given list of refs
 */
const useFocusLost = (callback: (event: Event) => void, refs: RefObject<Node>[]): void => {
  useEffect(() => {
    const handleClickAway = async (event: Event): Promise<void> => {
      // Only call the callback if none of the given refs have focus
      if (refs.every((ref) => !refContainsTarget(ref, event.target))) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickAway, true);
    document.addEventListener('focus', handleClickAway, true);

    return () => {
      document.removeEventListener('mousedown', handleClickAway, true);
      document.removeEventListener('focus', handleClickAway, true);
    };
  }, [callback, refs]);
};

export default useFocusLost;
