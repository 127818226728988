import { editor, IRange, Monaco, MonacoEditor } from 'monaco-editor';

import config from 'config';
import { GraphNode } from 'types';
import { NodeType } from 'types/api';

export function syntaxHighlighting(monaco: Monaco) {
  //// Highlighting stuff thrown here. TODO: move to separate function
  // Add syntax highlighting for jinja delimiters
  const jinjaTokens = [
    [/\{\{/, { token: 'delimiter.jinja' }],
    [/\}\}/, { token: 'delimiter.jinja' }],
  ];

  const allLangs = monaco.languages.getLanguages();
  // const pyLangDef = allLangs.find(({ id }) => id === 'python');
  const sqlLangDef = allLangs.find(({ id }) => id === 'sql');

  for (let langDef of [sqlLangDef]) {
    if (langDef == undefined) {
      continue;
    }
    // @ts-ignore
    langDef.loader().then((lang) => {
      const { language: mlang } = lang;
      if (!mlang.tokenizer.hasOwnProperty('root')) {
        mlang.tokenizer['root'] = [];
      }
      if (Array.isArray(jinjaTokens)) {
        mlang.tokenizer['root'].unshift.apply(mlang.tokenizer['root'], jinjaTokens);
      }
    });
  }
}

export const codeKey = (graphId: string, fileId: string) => `${graphId}-${fileId}`;

export function getPath(monaco: Monaco, codeKey: string) {
  return monaco.Uri.parse(codeKey);
}

export function getModel(monaco: Monaco, codeKey: string) {
  const path = getPath(monaco, codeKey);
  const model = monaco.editor.getModel(path);

  return model;
}

export function createModel(monaco: Monaco, codeKey: string, code: string, language?: string) {
  const path = getPath(monaco, codeKey);
  return monaco.editor.createModel(code, language, path);
}

export function addMarkers(monaco: Monaco, node: GraphNode, model: editor.ITextModel) {
  try {
    if (!model) return;

    const syntaxErrors = node.data.manifestErrors
      ? node.data.manifestErrors.filter((error) => error.source === 'syntax')
      : [];

    const syntaxMarkers = syntaxErrors
      .filter((syntaxError) => !!syntaxError.lineno)
      .map((syntaxError) => {
        return {
          ...syntaxError,
          severity: monaco.MarkerSeverity.Error,
          startLineNumber: syntaxError.lineno!,
          startColumn: 1,
          endLineNumber: syntaxError.lineno!,
          endColumn: model.getLineLength(syntaxError.lineno!) + 1,
        };
      });

    monaco.editor.setModelMarkers(model, 'owner', syntaxMarkers);
  } catch (e) {
    if (e instanceof Error) {
      // The line error comes from the server but if you delete a few lines
      // it is possible for the editor to briefly want to mark a line that is
      // greater than the number of lines available.
      if (!e.message.includes('Illegal value for lineNumber')) {
        throw e;
      }
    }
  }
}

export type Extraction = {
  type: string;
  content: string;
  direction?: 'output' | 'input';
  index: number;
  lineNumber: number;
  lineIndex: number;
  name?: string;
};

export function getLineFromIndex(index: number, code: string) {
  const slice = code.slice(0, index);
  const lines = slice.split('\n');
  const lastLine = lines[lines.length - 1];
  return { lineNumber: lines.length, lineIndex: lastLine.length };
}

export function extractPortsAndParameters(code: string, language: string): Extraction[] {
  const commentRegex = language === 'sql' ? /--.*/g : /#.*/g;
  const stripComments = code.replaceAll(commentRegex, '');
  const globalMatches = stripComments.matchAll(
    /(Table|Stream|Parameter)\([\s\S]*?(\'|\")(\w+)(\'|\")[\s\S]*?\)/gm
  );

  return Array.from(globalMatches)
    .filter((match) => !!match && !!match.index && !!match.input)
    .map((match) => {
      const [content, type, _, name] = match;

      // Matches either "w" or 'w' to determine write mode
      const writeMode = content.match(/(['"])w\1/);

      const { lineNumber, lineIndex } = getLineFromIndex(match.index!, match.input!);

      return {
        lineNumber,
        index: match.index!,
        lineIndex,
        content,
        type,
        name,
        direction: writeMode ? 'output' : 'input',
      };
    });
}

export function getRange(extraction: Extraction, monaco: Monaco): IRange {
  const lines = extraction.content.split('\n');
  const lastLine = lines[lines.length - 1];
  return new monaco.Range(
    extraction.lineNumber,
    extraction.lineIndex + 1,
    extraction.lineNumber + lines.length - 1,
    extraction.lineIndex + lastLine.length + 1
  );
}

export enum PortStatus {
  Configured = 'configured',
  UnConfigured = 'unConfigured',
  Undefined = 'undefined',
}

export enum ParameterStatus {
  Configured = 'configured',
  UnConfigured = 'unConfigured',
  Undefined = 'undefined',
}

export function doesPortExist(node: GraphNode, direction?: 'input' | 'output', name?: string) {
  const directionKey = direction === 'output' ? 'outputs' : 'inputs';
  return !!node.data[directionKey].find((port) => port.name === name);
}

export function isPortConfigured(node: GraphNode, direction?: 'input' | 'output', name?: string) {
  const edgeKey = direction === 'output' ? 'local_output_edges' : 'local_input_edges';
  return !!node.data[edgeKey].find((edge) => edge.data.port === name);
}

export function getPortStatus(node: GraphNode, direction?: 'input' | 'output', name?: string) {
  if (doesPortExist(node, direction, name)) {
    return isPortConfigured(node, direction, name)
      ? PortStatus.Configured
      : PortStatus.UnConfigured;
  } else {
    return PortStatus.Undefined;
  }
}

export function getParameterStatus(node: GraphNode, parameterName?: string) {
  const parameter = node.data.parameters.find((parameter) => parameter.name === parameterName);
  if (!parameter) return ParameterStatus.Undefined;
  if (parameter.required && parameter.value === null) return ParameterStatus.UnConfigured;

  return ParameterStatus.Configured;
}

export function getClassName(node: GraphNode, extraction: Extraction) {
  if (extraction.type === 'Parameter') {
    const parameterStatus = getParameterStatus(node, extraction.name);
    switch (parameterStatus) {
      case ParameterStatus.Configured:
        return 'editor__parameter';
      case ParameterStatus.UnConfigured:
        return 'editor__parameter--error';
      case ParameterStatus.Undefined:
      default:
        return 'editor__parameter--undefined';
    }
  } else {
    const portStatus = getPortStatus(node, extraction.direction, extraction.name);

    switch (portStatus) {
      case PortStatus.Configured:
        return 'editor__port';
      case PortStatus.UnConfigured:
        return 'editor__port--error';
      case PortStatus.Undefined:
      default:
        return 'editor__port--undefined';
    }
  }
}

function getDocsLink(extraction: Extraction, nodeType?: NodeType) {
  if (!nodeType) return config.docsUrl;
  const baseLink =
    nodeType === 'sql' ? `${config.docsUrl}/dev/sql` : `${config.docsUrl}/dev/python`;

  if (extraction.type === 'Parameter') {
    return `${baseLink}#using-parameters`;
  } else if (['Table', 'Stream'].includes(extraction.type)) {
    return `${baseLink}#working-with-data`;
  } else {
    return baseLink;
  }
}

export function addDecorations(
  editor: MonacoEditor,
  monaco: Monaco,
  node: GraphNode,
  model: editor.ITextModel,
  handles?: string[]
) {
  const code = model.getValue();
  const language = model.getLanguageId();
  const extractions = extractPortsAndParameters(code, language);

  const decorations = extractions.map((extraction) => {
    return {
      range: getRange(extraction, monaco),
      options: {
        className: getClassName(node, extraction),
        hoverMessage: {
          value: `[See documentation](${getDocsLink(extraction, node.data.type)})`,
          supportHtml: true,
        },
      },
    };
  });

  return editor.deltaDecorations(handles ? handles : [], decorations);
}
