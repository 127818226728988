import React, { useCallback, useMemo } from 'react';
import { FiCopy, FiRotateCcw, FiTrash2, FiX } from 'react-icons/fi';
import { Flex, FlexProps, IconButton, Tooltip } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import useAddNodeFromComponent from 'hooks/api/useAddNodeFromComponent';
import useDuplicateNode from 'hooks/api/useDuplicateNode';
import useGraph from 'hooks/api/useGraph';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { GraphState, NodeData } from 'types';
import {
  isComponentNodeType,
  isFunctionNodeType,
  isStoreNodeType,
  isSubgraphNodeType,
} from 'utils/nodes';
import RunButton from 'views/Graph/components/RunButton';
import useStore from 'views/Graph/state';

import EditableNodeName from './EditableNodeName';
import NodeIcon from './NodeIcon';

type NodePreviewHeaderProps = FlexProps & {
  id: string;
  data: NodeData;
  onlyShowRun?: boolean;
  onCloseClick?: () => void;
};

const tooltipPlacement = 'top-end';

const selector = (s: GraphState) => ({
  setNodeIdsToDelete: s.setNodeIdsToDelete,
  setResetStoreNode: s.setResetStoreNode,
  setResetNodeState: s.setResetNodeState,
});

function NodePreviewHeader({
  id,
  data,
  onlyShowRun = false,
  onCloseClick,
  ...rest
}: NodePreviewHeaderProps) {
  const { setNodeIdsToDelete, setResetNodeState, setResetStoreNode } = useStore(selector, shallow);

  const { mutate: duplicateNode, isLoading: isDuplicatingNode, isGraphSaving } = useDuplicateNode();
  const { mutate: addComponent, isLoading: isAddingNodeFromComponent } = useAddNodeFromComponent();
  const isLoading = isDuplicatingNode || isAddingNodeFromComponent;
  const { readOnly } = useReadOnlyMode();

  const isEditable = useMemo(() => {
    const isFromComponent = !!data.fromComponent;
    const { graphLevel } = useStore.getState();
    return !readOnly && (graphLevel === 'root' || (graphLevel !== 'root' && !isFromComponent));
  }, [data.fromComponent, readOnly]);

  const showDuplicate = !isSubgraphNodeType(data.type);

  const showResetStore = useMemo(() => {
    return isStoreNodeType(data.type);
  }, [data.type]);

  const showResetState = useMemo(() => {
    return (
      isFunctionNodeType(data.type) ||
      isComponentNodeType(data.type) ||
      isSubgraphNodeType(data.type)
    );
  }, [data.type]);

  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });

  const onDuplicate = useCallback(() => {
    const node = useStore.getState().nodes.find((node) => node.id === id);

    if (isComponentNodeType(data.type)) {
      addComponent({
        componentKey: data.fromComponent!,
        graphVersionUID: graph?.version_uid || '',
        position: {
          x: node ? Math.floor(node.position.x / 100) : 0,
          y: node ? Math.floor(node.position.y / 100) : 0,
        },
      });
    } else {
      duplicateNode(id);
    }
  }, [data.type, data.fromComponent, id, addComponent, graph?.version_uid, duplicateNode]);

  return (
    <Flex
      justifyContent="space-between"
      paddingX="12px"
      paddingY="6px"
      gap="12px"
      height="40px"
      {...rest}
    >
      <Flex gap="10px" alignItems="center" wordBreak="break-all">
        {data && (
          <Flex>
            <NodeIcon nodeData={data} />
          </Flex>
        )}
        {id && data && <EditableNodeName nodeId={id} nodeData={data} isEditable={isEditable} />}
      </Flex>
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        gap="12px"
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
        }}
      >
        {!onlyShowRun && (
          <>
            {showDuplicate && !readOnly && (
              <Tooltip label={'Duplicate Node'} placement={tooltipPlacement}>
                <IconButton
                  colorScheme="gray"
                  icon={<FiCopy />}
                  aria-label="Duplicate Node"
                  variant="ghost"
                  className="nodrag"
                  data-testid={'graph-node-buttongroup-duplicate'}
                  onClick={onDuplicate}
                  isLoading={isLoading}
                  disabled={isGraphSaving}
                />
              </Tooltip>
            )}
            {showResetStore && !readOnly && (
              <Tooltip label={'Reset Store'} placement={tooltipPlacement}>
                <IconButton
                  colorScheme="gray"
                  icon={<FiRotateCcw />}
                  aria-label="ResetStore"
                  variant="ghost"
                  className="nodrag"
                  onClick={() => {
                    setResetStoreNode(id);
                  }}
                />
              </Tooltip>
            )}
            {showResetState && !readOnly && (
              <Tooltip label={'Reset State'} placement={tooltipPlacement}>
                <IconButton
                  colorScheme="gray"
                  icon={<FiRotateCcw />}
                  aria-label="ResetState"
                  variant="ghost"
                  className="nodrag"
                  onClick={() => {
                    setResetNodeState(id);
                  }}
                />
              </Tooltip>
            )}
            {!readOnly && (
              <Tooltip label="Delete Node" placement={tooltipPlacement}>
                <IconButton
                  colorScheme="gray"
                  icon={<FiTrash2 />}
                  aria-label="DeleteNode"
                  variant="ghost"
                  className="nodrag"
                  onClick={() => id && setNodeIdsToDelete([id])}
                />
              </Tooltip>
            )}
          </>
        )}
        <RunButton nodeId={id} />
        {onCloseClick && (
          <IconButton
            colorScheme="gray"
            icon={<FiX />}
            aria-label="DeleteNode"
            variant="ghost"
            className="nodrag"
            onClick={() => onCloseClick()}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default NodePreviewHeader;
