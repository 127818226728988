import { FiChevronLeft, FiChevronRight, FiSkipBack, FiSkipForward } from 'react-icons/fi';
import { Box, ChakraProps, Flex, IconButton, Select } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';
import { colors } from 'styles/colors';

const PAGE_SIZES = [10, 25, 50, 100, 200];

type PaginationProps = ChakraProps & {
  page: number;
  count?: number;
  pageSize: number;
  showCount?: boolean;
} & (
    | {
        setPage: (page: number) => void;
        table?: never;
      }
    | {
        setPage?: never;
        table: Table<any>;
      }
  );

function Pagination({ page, setPage, count, pageSize, showCount = true, table }: PaginationProps) {
  let totalPage: number = 1;

  if (table) {
    totalPage = table.getPageCount();
  } else if (count) {
    totalPage = Math.ceil(count / pageSize);
  }

  const handleFirstPage = () => {
    if (table) {
      table.setPageIndex(0);
    } else {
      setPage(1);
    }
  };

  const handlePrevPage = () => {
    if (table) {
      table.previousPage();
    } else {
      setPage(page === 0 ? 0 : page - 1);
    }
  };

  const handleNextPage = () => {
    if (table) {
      table.nextPage();
    } else {
      setPage(page + 1);
    }
  };

  const handleLastPage = () => {
    if (table) {
      table.setPageIndex(table.getPageCount() - 1);
    } else {
      setPage(totalPage || 0);
    }
  };

  const isFirstOrPrevPageDisabled = table ? !table.getCanPreviousPage() : page === 1;
  const isNextOrLastPageDisabled = table ? !table.getCanNextPage() : page === totalPage;

  return (
    <Flex w="full">
      {table && (
        <Select
          w="auto"
          size="xs"
          defaultValue={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
        >
          {PAGE_SIZES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      )}

      <Flex ml="auto" textStyle="label" alignItems="center" flexShrink={0}>
        <IconButton
          variant="ghost"
          onClick={handleFirstPage}
          color={colors.white}
          disabled={isFirstOrPrevPageDisabled}
          mr={2}
          icon={<FiSkipBack />}
          aria-label="first"
        >
          first
        </IconButton>
        <IconButton
          variant="ghost"
          onClick={handlePrevPage}
          disabled={isFirstOrPrevPageDisabled}
          mr={2}
          icon={<FiChevronLeft />}
          aria-label="prev"
        />
        <IconButton
          variant="ghost"
          onClick={handleNextPage}
          disabled={isNextOrLastPageDisabled}
          mr={2}
          icon={<FiChevronRight />}
          aria-label="next"
        />
        {totalPage !== null && (
          <IconButton
            variant="ghost"
            onClick={handleLastPage}
            disabled={isNextOrLastPageDisabled}
            mr={2}
            aria-label="last"
            icon={<FiSkipForward />}
          />
        )}
        <Box color="text3" mr={2}>
          Page {page} of {totalPage}
        </Box>
        {showCount && count !== null && (
          <Box ml="auto" color="text3">
            {count} results
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

export default Pagination;
