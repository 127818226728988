import { XYPosition } from 'reactflow';
import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useAddNodeFromComponentURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

export type AddNodeComponentData = {
  position?: XYPosition;
  graphVersionUID: string;
  componentUID?: string;
  componentKey?: string;
};

function useAddNodeFromComponent(
  options?: UseMutationOptions<Graph, unknown, AddNodeComponentData>
) {
  const result = useGraphEditMutate<Graph, unknown, AddNodeComponentData>(
    async ({
      componentUID,
      componentKey,
      graphVersionUID,
      position = { x: 0, y: 0 },
    }: AddNodeComponentData): Promise<Graph> => {
      const url = useAddNodeFromComponentURL(graphVersionUID);
      const res = await Axios.post<Graph>(url, {
        component_uid: componentUID,
        component_key: componentKey,
        position_x: position.x,
        position_y: position.y,
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useAddNodeFromComponent;
