import { RefObject, useEffect } from 'react';

export default function usePreventZoom(ref: RefObject<HTMLElement>) {
  useEffect(() => {
    const preventDefault = (e: MouseEvent) => {
      // Mac trackpad zoom gestures simulates holding the ctrl key when scrolling
      e.ctrlKey && e.preventDefault();
    };

    const element = ref.current;

    if (element) {
      // Since this element is not part of the graph, user could zoom into browser window by accident and be trapped
      element.addEventListener('wheel', preventDefault, { passive: false });
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', preventDefault);
      }
    };
  }, [ref]);
}
