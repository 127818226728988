import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

import ModalComponent from 'components/Modal';
import useUpdateNodes from 'hooks/api/useUpdateNodes';

type ParametersProps = {
  nodeId: string;
  isOpen: boolean;
  closeModal: () => void;
};

export type WebhookParameterData = {
  name: string;
  value: string;
};

function AddWebhookParameterModal({ nodeId, isOpen, closeModal }: ParametersProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WebhookParameterData>();

  const { mutate: updateNodes, isLoading } = useUpdateNodes({
    onSettled: () => {
      closeModal();
    },
  });

  const onSubmit = (data: WebhookParameterData) => {
    updateNodes({ [nodeId]: { parameter_values: { [data.name]: data.value } } });
    closeModal();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      size="md"
      onClose={() => closeModal()}
      title="App Webhook Parameter"
      showFooter={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="webhook-parameter-name"
          mb={4}
          isInvalid={!!errors?.name}
          isRequired={true}
        >
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="webhook-parameter-name"
            type=""
            data-testid="webhook-parameter-name"
            {...register('name', { required: true })}
          />
          {errors.name && <FormErrorMessage>This field is required</FormErrorMessage>}
        </FormControl>

        <FormControl
          id="webhook-parameter-value"
          mb={4}
          isInvalid={!!errors?.value}
          isRequired={false}
        >
          <FormLabel>Value</FormLabel>
          <Input
            placeholder="webhook-parameter-value"
            type=""
            data-testid="webhook-parameter-value"
            {...register('value', { required: false })}
          />
          {errors.value && <FormErrorMessage>This field is required</FormErrorMessage>}
        </FormControl>

        <Button
          data-testid="submit"
          type="submit"
          size="md"
          w="100%"
          isLoading={isLoading}
          isDisabled={!!errors?.name || !!errors?.value}
          mt={4}
          mb={2}
        >
          Create
        </Button>
      </form>
    </ModalComponent>
  );
}

export default AddWebhookParameterModal;
