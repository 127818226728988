import { useCallback } from 'react';

import useGraph from './useGraph';
import useNodeModel from './useNodeModel';
import useUpdateNodeFile from './useUpdateNodeFile';

function useSaveNodeCode(
  fileId?: string | null,
  filePath?: string | null,
  fileName?: string | null
) {
  const { data: graph, isLoading: isGraphLoading } = useGraph({
    select: (data) => {
      const { uid, version_uid, graph_files } = data;
      return { uid, version_uid, graph_files };
    },
  });
  const { mutateAsync: updateFile, isLoading: isUpdatingFile, isGraphSaving } = useUpdateNodeFile();

  const {
    data: { code },
  } = useNodeModel(fileId, filePath);

  // returns true is the graph is up to date
  // returns false if the graph is out of date
  const save = useCallback(
    async (graphVersionId?: string): Promise<boolean> => {
      if (isGraphSaving) {
        return false;
      }

      const graphVersionUID = graphVersionId || graph?.version_uid;

      if (!filePath) {
        // if there's no file path, then it's a component and we don't need to save it
        return true;
      }

      const originalCode = graph?.graph_files[filePath];

      // only update if the code has changed
      if (
        code !== null &&
        code !== undefined &&
        graphVersionUID &&
        filePath &&
        fileName &&
        code !== originalCode
      ) {
        await updateFile({
          graph_version_uid: graphVersionUID,
          filePath,
          fileName,
          fileContent: code,
        });
        return false;
      } else {
        return true;
      }
    },
    [isGraphSaving, graph?.version_uid, graph?.graph_files, filePath, code, fileName, updateFile]
  );

  return {
    isLoading: isGraphLoading || isUpdatingFile,
    // if we're updating a file and the file is a graph.yml file
    isSavingGraphYml: isUpdatingFile && fileId === 'graph.yml',
    save,
  };
}

export default useSaveNodeCode;
