import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import { GraphState } from 'types';
import useStore from 'views/Graph/state';

const selector = (s: GraphState) => ({ readOnly: s.readOnly, setReadOnly: s.setReadOnly });

function useReadOnlyMode() {
  const { readOnly, setReadOnly } = useStore(selector, shallow);
  const toggle = useCallback(() => setReadOnly(!readOnly), [readOnly, setReadOnly]);
  const router = useRouter();

  // if we're not on the graph page, then should be read only
  const isNotGraphPage = useMemo(() => !router.pathname.startsWith('/graph'), [router.pathname]);
  const isMarketplaceModalOpen = useMemo(
    () => router.query?.marketplace === 'true',
    [router.query?.marketplace]
  );

  return { readOnly: readOnly || isNotGraphPage || isMarketplaceModalOpen, setReadOnly, toggle };
}

export default useReadOnlyMode;
