import { useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FiType } from 'react-icons/fi';
import { RiLock2Line } from 'react-icons/ri';
import { Flex } from '@chakra-ui/react';
import { components, OptionProps, Select } from 'chakra-react-select';

import useVariables from 'hooks/api/useVariables';
import { Parameter } from 'types';

export function formatSecret(name: string) {
  return `\${{ ${name} }}`;
}

type SecretOption = {
  label: string;
  value: string;
  isSecret: boolean;
};

const SecretOptionComponent = (props: OptionProps<SecretOption>) => (
  <components.Option {...props}>
    <Flex gap={3} alignItems="center">
      {props.data.isSecret ? <RiLock2Line /> : <FiType />}
      {props.data.label}
    </Flex>
  </components.Option>
);

type SelectSecretProps = {
  isRequired?: boolean;
  isLoading?: boolean;
  onChange?: (newValue: string | null) => void;
};

const SelectSecret = (props: SelectSecretProps & UseControllerProps<Parameter>) => {
  const { isRequired, isLoading = false, onChange } = props;
  const { data: variables } = useVariables();
  const { field } = useController(props);

  const options = useMemo<SecretOption[]>(() => {
    if (!variables) return [];
    return variables.results.map((variable) => ({
      label: variable.name,
      value: formatSecret(variable.name),
      isSecret: variable.secret,
    }));
  }, [variables]);

  const value = field.value
    ? options.find(
        (option) => option.value.replaceAll(/\s/g, '') === field.value.replaceAll(/\s/g, '')
      )
    : undefined;

  return (
    <Select
      {...field}
      isLoading={isLoading}
      isDisabled={isLoading}
      options={options}
      placeholder="Secret..."
      closeMenuOnSelect={true}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 10 }),
      }}
      size="sm"
      onChange={(newValue) => {
        if (newValue) {
          onChange?.(newValue.value);
        } else {
          onChange?.(null);
        }
      }}
      value={value}
      isClearable={!isRequired}
      components={{
        Option: SecretOptionComponent,
      }}
      isMulti={false}
    />
  );
};

export default SelectSecret;
