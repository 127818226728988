import { Dispatch, memo, SetStateAction, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid } from 'react-window';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  ChakraProps,
  Flex,
  HStack,
  Spinner,
  Table,
  Tbody,
  Th,
  Thead,
} from '@chakra-ui/react';
import {
  Cell,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import useStoreMetadata from 'hooks/api/useStoreMetadata';

import CellComponent from './Cell';
import Pagination from './Pagination';

const DEFAULT_COLUMN_WIDTH = 150;

export type TableProps = ChakraProps & {
  id: string;
  count: number | null;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pageIndex: number;
  pageSize: number;
  isLoading: boolean;
  showPagination?: boolean;
  footerChildren?: JSX.Element | null;
  rawData?: any[];
  showSchema?: boolean;
  storeNodeId?: string | null;
};

function TableView({
  id,
  isLoading,
  pageSize,
  pageIndex,
  count,
  footerChildren,
  showPagination = true,
  rawData,
  showSchema = false,
  storeNodeId,
  setPagination,
  ...rest
}: TableProps) {
  const columnHelper = createColumnHelper<Record<string, any>>();
  const { data } = useStoreMetadata(storeNodeId || '');

  const schema = useMemo(() => {
    return data?.persisted_schema?.schema_dict.fields.map((field) => field.field_type) || null;
  }, [data]);

  const headers = useMemo(
    () => (rawData && rawData.length > 0 ? Object.keys(rawData[0]) : []),
    [rawData]
  );

  const [selectedCell, setSelectedCell] = useState<Cell<any, unknown>>();

  const columns = useMemo(() => {
    return rawData
      ? [
          columnHelper.accessor('', {
            id: 'defaultId',
            cell: (info) =>
              info.row.index + 1 + pageIndex * pageSize >= 100000
                ? `...${(info.row.index + 1 + pageIndex * pageSize) % 100000}`
                : info.row.index + 1 + pageIndex * pageSize,
            size: 10,
          }),
          ...headers.map((key, index) =>
            // add custom accessor function to get it to work with columns that have a . in them
            columnHelper.accessor((d) => d[key], {
              id: key,
              cell: (info) => (
                <CellComponent
                  value={info && info.getValue()}
                  type={schema && schema.length > index ? schema[index] : null}
                  isSelected={info.cell.id === selectedCell?.id}
                  header={key}
                  setSelectedCell={setSelectedCell}
                />
              ),
              size: DEFAULT_COLUMN_WIDTH,
            })
          ),
        ]
      : [];
  }, [rawData, columnHelper, headers, pageIndex, pageSize, schema, selectedCell?.id]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const gridRef = useRef<VariableSizeGrid | null>(null);

  const rerenderGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterColumnIndex(0);
    }
  };

  const getPageCount = () => {
    if (count) return Math.ceil(count / pageSize);
    // Webhooks don't have a count, so we make an assumption that if the data is an exact multiple of the page size, there is another page
    if (rawData && rawData.length > 0) {
      const remainder = rawData.length % pageSize;
      const pages = Math.ceil(rawData.length / pageSize);
      return remainder === 0 ? pages + 1 : pages;
    }
    return 1;
  };

  const tableInstance = useReactTable({
    data: rawData || [],
    columns,
    columnResizeMode: 'onChange',
    pageCount: getPageCount(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = tableInstance.getRowModel();

  const rowCount = rawData && rawData.length ? rawData.length : 0;

  function renderCell({ columnIndex, rowIndex, style }: any) {
    const row = rows[rowIndex];
    if (!row) return <div>Loading...</div>;
    const cell = row.getAllCells()[columnIndex];

    let extraStyles = {};
    if (columnIndex === 0) {
      extraStyles = { paddingLeft: 0, paddingRight: 0, textAlign: 'center' };
    }

    return (
      <td
        key={cell.id}
        className={columnIndex === 0 ? 'tableViewTdTop' : 'tableViewTd'}
        onClick={() => {
          cell.id !== selectedCell?.id && setSelectedCell(cell);
        }}
        style={{
          ...style,
          ...extraStyles,
          outline: cell.id === selectedCell?.id ? '1px solid #4C6EF5' : '0px',
          width: columnIndex === 0 ? 50 : Math.max(columnWidths[columnIndex], 50),
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }

  const oldColumnWidthsRef = useRef<string>('');
  const columnWidths = tableInstance.getAllColumns().map((column) => column.getSize());
  if (JSON.stringify(columnWidths) !== oldColumnWidthsRef.current) {
    rerenderGrid();
    oldColumnWidthsRef.current = JSON.stringify(columnWidths);
  }

  return (
    <Flex height="100%" flexDir="column" {...rest} overflow="hidden">
      <Box position="relative" height="100%">
        {columns ? (
          <Box height="100%" width="100%">
            <Table
              size="sm"
              style={{ tableLayout: 'fixed' }}
              height="100%"
              className="cancel-grid-drag"
            >
              <AutoSizer onResize={rerenderGrid}>
                {({ width, height }) => (
                  <VariableSizeGrid
                    ref={gridRef}
                    rowHeight={() => 22}
                    rowCount={rowCount}
                    width={width}
                    height={height}
                    onScroll={() => {
                      setSelectedCell(undefined);
                    }}
                    columnCount={columns.length}
                    columnWidth={(index) => (index === 0 ? 50 : Math.max(columnWidths[index], 50))}
                    innerElementType={({ children, style, ...rest }: any) => (
                      <>
                        <Thead style={{ height: '30px', position: 'sticky', top: 0, zIndex: 2 }}>
                          <tr>
                            {tableInstance.getHeaderGroups().map((headerGroup) => {
                              return headerGroup.headers.map((header, index) => (
                                <Th
                                  key={header.id}
                                  colSpan={header.colSpan}
                                  minWidth={
                                    index === 0 ? '50px' : `${Math.max(columnWidths[index], 50)}px`
                                  }
                                  maxWidth={
                                    index === 0 ? '50px' : `${Math.max(columnWidths[index], 50)}px`
                                  }
                                  bgColor="#f6f7f9"
                                  color="#1c2127"
                                  fontSize="12px"
                                  fontWeight={400}
                                  textTransform="none"
                                  px={2.5}
                                  py={0}
                                  h="30px"
                                  borderBottom="0px"
                                  boxShadow={`inset 0 -1px 0 rgb(17 20 24 / 15%), ${
                                    index === 0 ? '' : 'inset '
                                  }1px 0 0 rgb(17 20 24 / 15%)`}
                                  position="relative"
                                  userSelect="none"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  <HStack
                                    spacing={2}
                                    cursor={index > 0 ? 'pointer' : 'inherit'}
                                    onClick={header.column.getToggleSortingHandler()}
                                    display="flex"
                                    height="100%"
                                  >
                                    <Box>
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === 'desc' ? (
                                          <TriangleDownIcon aria-label="sorted descending" />
                                        ) : (
                                          <TriangleUpIcon aria-label="sorted ascending" />
                                        )
                                      ) : null}
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </Box>
                                  </HStack>
                                  {index > 0 && (
                                    <div
                                      {...{
                                        onMouseDown: header.getResizeHandler(),
                                        onTouchStart: header.getResizeHandler(),
                                        className: `resizer ${
                                          header.column.getIsResizing() ? 'isResizing' : ''
                                        }`,
                                      }}
                                    />
                                  )}
                                </Th>
                              ));
                            })}
                          </tr>
                        </Thead>
                        <Tbody position="relative">
                          <tr {...rest} style={style}>
                            {children}
                          </tr>
                        </Tbody>
                      </>
                    )}
                  >
                    {renderCell}
                  </VariableSizeGrid>
                )}
              </AutoSizer>
            </Table>
          </Box>
        ) : (
          <Center mt={8}>
            <Spinner size="lg" />
          </Center>
        )}
      </Box>

      {showPagination && (
        <Flex
          alignItems="center"
          px={2}
          py={2}
          height="30px"
          borderTopColor="border1"
          borderTopWidth={1}
          borderTopStyle="solid"
        >
          {footerChildren}
          <Pagination
            page={pageIndex + 1}
            pageSize={pageSize}
            table={tableInstance}
            count={count || rawData?.length}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default memo(TableView);
