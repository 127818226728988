import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import shallow from 'zustand/shallow';

import useSaveNodeCode from 'hooks/api/useSaveNodeCode';
import useInvalidateNodeExecutions from 'hooks/useInvalidateNodeExecutions';
import useStoreNode from 'hooks/useStoreNode';
import { EditorWindowTab, GraphState } from 'types';
import { NodeExecution } from 'types/api';
import { useTriggerGraphURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { isComponentNodeType, isFunctionNodeType, isSubgraphNodeType } from 'utils/nodes';
import useEditorStore, { EditorState } from 'views/Graph/modules/CodeEditor/state';
import useGraphStore from 'views/Graph/state';

import useSaveParameters from './useSaveParameters';

export type TriggerResponse = {
  run_uid: string;
  executions: NodeExecution[];
};

const selectorEditor = (s: EditorState) => s.setIsExecuting;
const selectorGraph = (s: GraphState) => ({
  openTab: s.openTab,
});

const selectorGraphSaving = (s: GraphState) => ({ graphSaving: s.graphSaving });

function useTriggerManager(nodeId?: string, isRunAll?: boolean) {
  const { graphSaving } = useGraphStore(selectorGraphSaving, shallow);

  const triggerGraph = useTriggerGraph(nodeId, isRunAll);
  const originalTrigger = triggerGraph.mutate;

  const node = useStoreNode(nodeId || null);
  const { save: saveCode } = useSaveNodeCode(nodeId, node?.data?.filePath, node?.data?.name);
  const { save: saveParameters } = useSaveParameters();

  const [queueTrigger, setQueueTrigger] = useState<null | boolean>(null);

  const isComponentOrSubgraph = useMemo(
    () => isComponentNodeType(node?.data?.type) || isSubgraphNodeType(node?.data?.type),
    [node?.data?.type]
  );

  const [triggering, setTriggering] = useState(false);

  const isFunctionNode = useMemo(() => isFunctionNodeType(node?.data?.type), [node?.data?.type]);

  const newTrigger = useCallback(async () => {
    if (!isRunAll && node?.data?.type !== undefined && !isFunctionNode && !isComponentOrSubgraph) {
      // if we're not running all, node has a type (so we're not testing) and it's not a function or component
      return; // don't trigger
    }

    setTriggering(true);
    const graph = await saveParameters(); // Save any parameter values that have not been saved yet
    // If run all mode and no node is selected, there is no need to check if code is up to date
    // or if the node is a component/subgraph
    if ((!nodeId && isRunAll) || isComponentOrSubgraph) {
      originalTrigger();
    } else {
      const codeUpToDate = graph ? await saveCode(graph.version_uid) : await saveCode(); // if the code is dirty, then save it first
      if (codeUpToDate) {
        originalTrigger(); // if the code is up to date, then trigger the graph
      } else {
        setQueueTrigger(true); // if the code isn't up to date, then queue the trigger to rerun
      }
    }
    setTriggering(false);
  }, [
    isRunAll,
    nodeId,
    originalTrigger,
    saveCode,
    saveParameters,
    isComponentOrSubgraph,
    isFunctionNode,
    node?.data?.type,
    setTriggering,
  ]);

  useEffect(() => {
    if (!graphSaving && queueTrigger) {
      newTrigger();
      setQueueTrigger(null);
    }
  }, [queueTrigger, graphSaving, newTrigger, setQueueTrigger]);

  triggerGraph.mutate = newTrigger;

  triggerGraph.isLoading = triggering || queueTrigger || triggerGraph.isLoading;

  return triggerGraph;
}

function useTriggerGraph(nodeId?: string, isRunAll?: boolean) {
  const setIsExecuting = useEditorStore(selectorEditor);
  const invalidateNodeExecutions = useInvalidateNodeExecutions();
  const url = useTriggerGraphURL()!;

  const { openTab } = useGraphStore(selectorGraph, shallow);

  const mutation = useMutation<TriggerResponse, unknown>(
    async () => {
      setIsExecuting(true);

      try {
        const res = await Axios.post<TriggerResponse>(url, {
          node_id: isRunAll ? null : nodeId,
          local_execution: false,
        });
        if (nodeId) {
          openTab({ name: nodeId, bottomTab: 'Console' } as EditorWindowTab);
        }

        // update execution for any nodes in this run in the GraphStatus
        res?.data?.executions?.forEach((execution) => {
          invalidateNodeExecutions(execution.node_id, execution);
        });

        setIsExecuting(false);

        return res?.data;
      } catch (error: any) {
        setIsExecuting(false);
        throw error;
      }
    },
    {
      retry: 0,
    }
  );

  return mutation;
}

export default useTriggerManager;
