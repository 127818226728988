import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { NodeExecution } from 'types/api';
import { useCancelExecutionURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useCancelExecution(options?: UseMutationOptions<NodeExecution, unknown, string>) {
  const result = useMutation<NodeExecution, unknown, string>(
    async (executionUID): Promise<NodeExecution> => {
      const url = useCancelExecutionURL(executionUID);
      const res = await Axios.post<NodeExecution>(url);
      return res.data;
    },
    options
  );
  return result;
}

export default useCancelExecution;
