import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { StoreData } from 'types/api';
import { useStoreNodeDataURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StoreNodeDataQueryKey } from 'utils/queryKeys';

import { useGraphUid } from './useGraph';

export const pageSize = 500;

export const maxPages = 4;

function makeQueryKey(nodeId: string | null) {
  return [...(StoreNodeDataQueryKey(nodeId) as Array<any>), 'chartData'];
}

function useChartNodeData(
  nodeId: string | null,
  storeType: string = 'table',
  graphUID?: string | null
) {
  const uid = useGraphUid();

  const url = useStoreNodeDataURL(graphUID || uid);
  const queryKey = makeQueryKey(nodeId);

  const [dataTrimmed, setDataTrimmed] = useState(false);

  const res = useInfiniteQuery<StoreData>(
    queryKey,
    async ({ pageParam = 1 }) => {
      const res = await Axios.get<StoreData>(url!, {
        params: {
          node_id: nodeId,
          store_type: storeType,
          page: pageParam,
          page_size: pageSize,
        },
      });

      if (res.data.count !== null && res.data.count > pageSize * maxPages && !dataTrimmed) {
        setDataTrimmed(true);
      }

      return res.data;
    },
    {
      enabled: !!url && !!nodeId,
      getNextPageParam: (lastPage, allPages) => {
        return !!lastPage.next && allPages.length < maxPages && allPages.length + 1;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        return firstPage.previous && allPages.length - 1;
      },
    }
  );

  const data = useMemo(() => {
    const storeData = res?.data?.pages ? res?.data?.pages.map((p) => p.results).flat() : [];

    return { results: storeData, count: storeData.length, next: null, previous: null } as StoreData;
  }, [res?.data?.pages]);

  useEffect(() => {
    // if there's a next page, and we're not already fetching it, fetch it
    if (!res.isFetchingNextPage && res.hasNextPage) {
      res.fetchNextPage();
    }
  }, [res]);

  return { ...res, data: { data: data, dataTrimmed } };
}

export default useChartNodeData;
