import { useCallback } from 'react';

import { Graph } from 'types/api';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';
import useUpdateNodes from './useUpdateNodes';

function useSaveParameters() {
  const { mutateAsync: updateNodes, isLoading } = useUpdateNodes();

  const { data: graphNodes } = useGraph({
    select: (data) => {
      return data.manifest.nodes_by_id;
    },
  });

  const save = useCallback(async (): Promise<Graph | void> => {
    if (!graphNodes) return;
    const { nodes } = useStore.getState();

    const parameters = nodes.reduce<Record<string, any>>((nodeAccumulator, node) => {
      const graphNode = graphNodes[node.id];
      if (!graphNode) return nodeAccumulator;

      const inSubNode = !!node.data.parentNodeId;

      const graphParameters = inSubNode
        ? graphNode.resolved_parameter_values
        : graphNode.parameter_values;

      if (!graphParameters) return nodeAccumulator;

      const parameters = inSubNode ? node.data.resolvedParameters : node.data.parameters;

      if (parameters.length === 0 && graphParameters.length === 0) return nodeAccumulator;

      const parametersKeyValue = parameters.reduce<Record<string, any>>(
        (parameterAccumulator, parameter) => {
          // use == instead of === since we want to treat null and undefined as equal
          if (graphParameters[parameter.name] == parameter.value) return parameterAccumulator;
          parameterAccumulator[parameter.name] = parameter.value;
          return parameterAccumulator;
        },
        {}
      );

      if (Object.keys(parametersKeyValue).length > 0) {
        nodeAccumulator[node.id] = {
          parameter_values: parametersKeyValue,
        };
      }

      return nodeAccumulator;
    }, {});

    if (Object.keys(parameters).length > 0) {
      return await updateNodes(parameters);
    } else {
      return Promise.resolve();
    }
  }, [graphNodes, updateNodes]);

  return {
    isLoading,
    save,
  };
}

export default useSaveParameters;
