import { MouseEvent, useCallback, useMemo } from 'react';
import { FiPlay, FiXOctagon } from 'react-icons/fi';
import { Box, Button, ButtonProps, Center, Tooltip } from '@chakra-ui/react';
import { pingAnimation } from 'styles/animations';
import shallow from 'zustand/shallow';

import { Label } from 'components/Typography';
import useCancelExecution from 'hooks/api/useCancelExecution';
import useTriggerGraph from 'hooks/api/useTriggerGraph';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import {
  isChartNodeType,
  isMarkdownNodeType,
  isStoreNodeType,
  isWebhookNodeType,
} from 'utils/nodes';
import Counter from 'views/Graph/modules/NodePanel/Logs/Counter';
import useStore from 'views/Graph/state';

const tooltipPlacement = 'top-end';

type RunButtonProps = ButtonProps & {
  nodeId: string;
  showPing?: boolean;
};

const RunButton = ({ nodeId, showPing = true, ...rest }: RunButtonProps) => {
  const { readOnly } = useReadOnlyMode();

  const [setExecutionPlanNode] = useStore((state) => [state.setExecutionPlanNode], shallow);

  const { isNodeCancellable, execution, node } = useStore(
    useCallback(
      (state) => ({
        isNodeCancellable:
          nodeId && (state.queuedNodeIds.includes(nodeId) || state.runningNodeIds.includes(nodeId)),
        execution: state.GraphStatus?.executions[nodeId],
        node: state.nodes.find((node) => node.id === nodeId),
      }),
      [nodeId]
    ),
    shallow
  );

  const showExecute = useMemo(() => {
    return (
      !isWebhookNodeType(node?.data?.type) &&
      !isStoreNodeType(node?.data?.type) &&
      !isChartNodeType(node?.data?.type) &&
      !isMarkdownNodeType(node?.data?.type)
    );
  }, [node?.data?.type]);

  const { mutate: cancelExecution, isLoading: isLoadingCancel } = useCancelExecution();

  const onCancel = useCallback(() => {
    if (!execution?.uid) return;
    cancelExecution(execution.uid);
  }, [execution?.uid, cancelExecution]);

  const handleExecutionHover = useCallback(() => {
    setExecutionPlanNode(nodeId);
  }, [nodeId, setExecutionPlanNode]);

  const clearExecutionPlan = useCallback(() => {
    setExecutionPlanNode(null);
  }, [setExecutionPlanNode]);

  const { mutate: trigger, isLoading: isTriggeringNode } = useTriggerGraph(nodeId);

  const onTrigger = useCallback(
    (_: MouseEvent) => {
      clearExecutionPlan();

      trigger();
    },
    [clearExecutionPlan, trigger]
  );

  return (
    <>
      {showExecute && !readOnly && (
        <>
          {isNodeCancellable ? (
            <>
              {showPing && (
                <Tooltip
                  label={<Counter startTime={execution?.started} preText={'Executing for:'} />}
                  placement={tooltipPlacement}
                >
                  <Center mr={3}>
                    <Box
                      w={`12px`}
                      h={`12px`}
                      opacity={0.75}
                      bg={'action'}
                      borderRadius="50%"
                      animation={pingAnimation}
                    />
                    <Box
                      position={'absolute'}
                      w={`10px`}
                      h={`10px`}
                      bg={'action'}
                      borderRadius="50%"
                    />
                  </Center>
                </Tooltip>
              )}

              <Tooltip label="Cancel Node Execution" placement={tooltipPlacement}>
                <Button
                  className="nodrag"
                  aria-label="Cancel execute Node"
                  onClick={onCancel}
                  isLoading={isLoadingCancel}
                  colorScheme="gray"
                  {...rest}
                >
                  <FiXOctagon />
                  <Label paddingLeft={1}>Cancel</Label>
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip label="Run Node" placement={tooltipPlacement}>
              <Button
                className="nodrag"
                aria-label="Execute Node"
                onClick={(event) => onTrigger(event)}
                onMouseEnter={() => handleExecutionHover()}
                onMouseLeave={clearExecutionPlan}
                isLoading={isTriggeringNode}
                {...rest}
              >
                <FiPlay />
                <Label paddingLeft={1}>Run</Label>
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default RunButton;
