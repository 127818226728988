import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import Editor from '@monaco-editor/react';
import Image from 'next/image';
import shallow from 'zustand/shallow';

import { useParameter } from 'hooks/api/useGraph';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import useVariables from 'hooks/api/useVariables';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import useStore from 'state';
import { AppState, Parameter } from 'types';
import { Connection, ParameterType, Variable } from 'types/api';
import { getSVGPath } from 'utils/connections';
import { hasAuthForm } from 'views/Connections/Extra';
import useConnect from 'views/Connections/hooks/useConnect';
import useConnectionTypes from 'views/Connections/hooks/useConnectionTypes';

import { isSecret } from './Parameters';
import SelectConnection from './SelectConnection';
import SelectSecret, { formatSecret } from './SelectSecret';

const selector = (state: AppState) => ({
  setSecretsModalProps: state.setSecretsModalProps,
  setConnectionsModalProps: state.setConnectionsModalProps,
});

type ParameterFormProps = {
  nodeId: string;
  parameter: Parameter;
  onSubmit?: () => void;
};

const ParameterForm = ({ nodeId, parameter, onSubmit }: ParameterFormProps) => {
  const { setSecretsModalProps, setConnectionsModalProps } = useStore(selector, shallow);
  const { data: variables } = useVariables();

  const { mutateAsync: updateNodes, isLoading: isSaving } = useUpdateNodes();
  const { data: connectionTypes, isLoading: isLoadingConnectionTypes } = useConnectionTypes();
  const parameterValue = useParameter(nodeId, parameter.name);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<Parameter>({
    defaultValues: {
      ...parameter,
      value: parameterValue || parameter.default,
    },
  });

  useEffect(() => {
    setValue('value', parameterValue);
  }, [parameterValue, setValue]);

  const inputValue = watch('value');

  const isSecretMatch = useMemo(() => isSecret(inputValue), [inputValue]);
  const [textType, setTextType] = useState<string>(isSecretMatch ? 'secret' : 'text');

  const selectedVariable = variables?.results.find((variable) => {
    if (!isSecretMatch) return false;
    return variable.name === isSecretMatch[1];
  });

  function renderTextInput() {
    if (textType === 'secret') {
      return (
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <SelectSecret
              isLoading={isSaving}
              control={control}
              isRequired={parameter.required}
              {...field}
              onChange={async (newValue) => {
                field.onChange(newValue);
                await updateNodes({
                  [nodeId]: { parameter_values: { [parameter.name]: newValue } },
                });
                onSubmit?.();
              }}
            />
          )}
        />
      );
    } else {
      return (
        <Textarea {...register('value', { required: parameter.required })} rows={1} minH="40px" />
      );
    }
  }

  function renderInput() {
    switch (parameter.parameter_type) {
      case ParameterType.Text:
        return renderTextInput();
      case ParameterType.Boolean:
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => <Switch isChecked={field.value} {...field} />}
          />
        );
      case ParameterType.Integer:
        return (
          <Input
            type="number"
            {...register('value', { required: parameter.required, valueAsNumber: true })}
          />
        );
      case ParameterType.Float:
        return (
          <Input
            type="number"
            step="any"
            {...register('value', { required: parameter.required })}
          />
        );
      case ParameterType.Date:
        return <Input type="date" {...register('value', { required: parameter.required })} />;
      case ParameterType.DateTime:
        return (
          <Input type="datetime-local" {...register('value', { required: parameter.required })} />
        );
      case ParameterType.List:
        return (
          <Input
            placeholder="Comma separated list"
            {...register('value', { required: parameter.required })}
          />
        );
      case ParameterType.Schema:
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <Editor
                {...field}
                height="80px"
                width="100%"
                theme="patterns-theme-light"
                language="json"
                options={{
                  minimap: {
                    enabled: false,
                  },
                  padding: {
                    top: 5,
                  },
                  scrollBeyondLastLine: false,
                  renderLineHighlight: 'none',
                  overviewRulerLanes: 0,
                  automaticLayout: true,
                  wordBasedSuggestions: false,
                }}
                keepCurrentModel={true}
                key={parameter.name}
                path={parameter.name}
              />
            )}
          />
        );
      case ParameterType.Connection:
        return (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <SelectConnection
                isLoading={isSaving}
                control={control}
                type={parameter.connection_type}
                {...field}
                onChange={async (newValue) => {
                  field.onChange(newValue);
                  await updateNodes({
                    [nodeId]: { parameter_values: { [parameter.name]: newValue } },
                  });
                  onSubmit?.();
                }}
              />
            )}
          />
        );
      default:
        return renderTextInput();
    }
  }

  const handleConnect = useConnect();

  const handleConnectionResult = async (connectionResult?: Connection) => {
    if (!connectionResult) return;
    const newValue = `\${{ connection.${connectionResult.name} }}`;
    setValue('value', newValue);
    await updateNodes({
      [nodeId]: { parameter_values: { [parameter.name]: newValue } },
    });
    onSubmit?.();
  };

  const handleNewConnection = (connectionTypeString: string) => {
    const connectionType = connectionTypes?.results.find(
      (type) => type.connection_type === connectionTypeString
    );
    if (!connectionType) return;
    if (hasAuthForm(connectionType)) {
      setConnectionsModalProps({
        show: true,
        connectionForm: connectionType,
        onSuccess: handleConnectionResult,
      });
    } else {
      setConnectionsModalProps({
        show: true,
        connectingTo: { connectionType },
      });
      handleConnect(connectionType, {
        onSuccess: handleConnectionResult,
      });
    }
  };

  const handleConvertToSecret = () => {
    setSecretsModalProps({
      name: parameter.name,
      description: parameter.description,
      value: inputValue,
      show: true,
      onSuccess: (secret: Variable) => {
        const secretValue = formatSecret(secret.name);
        updateNodes({
          [nodeId]: { parameter_values: { [parameter.name]: secretValue } },
        });
        setValue('value', secretValue);
        setTextType('secret');
        onSubmit?.();
      },
    });
  };

  const handleNewSecret = () => {
    setSecretsModalProps({
      name: parameter.name,
      description: parameter.description,
      value: '',
      show: true,
      onSuccess: (secret: Variable) => {
        const secretValue = formatSecret(secret.name);
        updateNodes({
          [nodeId]: { parameter_values: { [parameter.name]: secretValue } },
        });
        setValue('value', secretValue);
        onSubmit?.();
      },
    });
  };

  const handleEditSecret = () => {
    if (!selectedVariable) return;
    setSecretsModalProps({
      ...selectedVariable,
      isSecret: selectedVariable.secret,
      value: selectedVariable.secret ? '********' : selectedVariable.value,
      show: true,
      isEditMode: true,
      onSuccess: (secret: Variable) => {
        updateNodes({
          [nodeId]: { parameter_values: { [parameter.name]: formatSecret(secret.name) } },
        });
      },
    });
  };

  function renderConnectionButton(connectionType: string) {
    return (
      <Button
        variant="outline"
        size="sm"
        onClick={() => handleNewConnection(connectionType)}
        disabled={isLoadingConnectionTypes}
        leftIcon={
          <Image
            src={`/images/connections/${getSVGPath(connectionType)}.svg`}
            height={20}
            width={20}
            alt="icon"
          />
        }
      >
        New connection
      </Button>
    );
  }

  const { readOnly } = useReadOnlyMode();

  return (
    <form
      onSubmit={handleSubmit(async (parameter) => {
        if (readOnly) return;
        await updateNodes({
          [nodeId]: { parameter_values: { [parameter.name]: parameter.value } },
        });
        onSubmit?.();
      })}
    >
      <Flex flexDirection="column" gap={3}>
        {(parameter.parameter_type === ParameterType.Text || !parameter.parameter_type) && (
          <FormControl>
            <FormLabel>Type</FormLabel>
            <RadioGroup size="sm" onChange={(nextValue) => setTextType(nextValue)} value={textType}>
              <Stack spacing={5} direction="row">
                <Radio value="text">Text</Radio>
                <Radio value="secret">Secret</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
        <FormControl
          id="value"
          isRequired={
            parameter.parameter_type === ParameterType.Boolean ? false : parameter.required
          }
        >
          <FormLabel>{parameter.name}</FormLabel>
          <FormHelperText>{parameter.description}</FormHelperText>
          {renderInput()}
        </FormControl>
        <Flex flexDirection="row" gap={1} alignItems="center" justifyContent="flex-end">
          {parameter.parameter_type === ParameterType.Connection &&
            renderConnectionButton(parameter.connection_type)}
          {(parameter.parameter_type === ParameterType.Text || !parameter.parameter_type) &&
            textType === 'text' &&
            !!inputValue && (
              <Button
                variant="outline"
                disabled={!inputValue || (!!selectedVariable && !isDirty) || readOnly}
                size="sm"
                onClick={handleConvertToSecret}
              >
                Convert to Secret
              </Button>
            )}
          {(parameter.parameter_type === ParameterType.Text || !parameter.parameter_type) &&
            textType === 'secret' && (
              <Button
                variant="outline"
                size="sm"
                disabled={!selectedVariable || !inputValue || readOnly}
                onClick={handleEditSecret}
              >
                Edit Secret
              </Button>
            )}
          {(parameter.parameter_type === ParameterType.Text || !parameter.parameter_type) &&
            textType === 'secret' && (
              <Button size="sm" onClick={handleNewSecret} disabled={readOnly}>
                New Secret
              </Button>
            )}
          {parameter.parameter_type !== ParameterType.Connection && textType !== 'secret' && (
            <Button type="submit" size="sm" isLoading={isSaving} disabled={readOnly || isSaving}>
              Save
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

export default memo(ParameterForm);
