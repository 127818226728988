import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useUpdateFileURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

type UseUpdateNodeFile = {
  graph_version_uid: string;
  filePath: string;
  fileName: string;
  fileContent: string;
  overrideCache?: boolean;
};

function useUpdateNodeFile(options?: UseMutationOptions<Graph, unknown, UseUpdateNodeFile>) {
  const mutation = useGraphEditMutate<Graph, unknown, UseUpdateNodeFile>(
    async ({
      graph_version_uid: graphVersionUID,
      filePath,
      fileName,
      fileContent,
      overrideCache = false,
    }: UseUpdateNodeFile) => {
      const file = new File([fileContent], fileName, {
        type: 'text/plain',
      });

      const formData = new FormData();
      formData.append('file', file);

      const url = useUpdateFileURL(graphVersionUID);
      const res = await Axios.post<Graph>(url, formData, {
        params: {
          file_path: filePath,
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // if overrideCache -> then don't add the new version_uid to the store
      if (res.data.version_uid && !overrideCache) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res?.data;
    },
    options
  );

  return mutation;
}

export default useUpdateNodeFile;
