import { useMemo } from 'react';
import { VisualizationSpec } from 'vega-embed';

import { StoreData } from 'types/api';

import useVegaTheme from './useVegaTheme';

export type VisData = {
  table: any[];
};

export type VegaSpecResult = {
  data: VisData;
  spec?: VisualizationSpec;
  error?: string;
  dataTrimmed: boolean;
};

function useVegaSpecInner(
  code: string | undefined,
  nodeType: string | null,
  nodeTitle: string | null,
  data: StoreData | undefined,
  dataTrimmed?: boolean
): VegaSpecResult {
  const vegaTheme = useVegaTheme();

  const { spec, error } = useMemo(() => {
    if (typeof code === 'undefined' || code === '' || nodeType !== 'chart') {
      return { error: 'No chart spec provided' };
    }

    let jsonCode = '';

    try {
      jsonCode = JSON.parse(code);
    } catch (e) {
      return { error: (e as Error).message };
    }

    const userSpec: VisualizationSpec = { ...(jsonCode as unknown as VisualizationSpec) };

    // we always want to make sure the spec has the correct schema, width, height and data
    userSpec.$schema = 'https://vega.github.io/schema/vega-lite/v5.json';
    // @ts-ignore
    userSpec.width = 'container';
    // @ts-ignore
    userSpec.height = 300;
    userSpec.data = { name: 'table' };
    userSpec.config = vegaTheme;

    if (userSpec.title == null && !!nodeTitle) {
      userSpec.title = nodeTitle;
    }

    return { spec: userSpec };
  }, [code, nodeType, vegaTheme, nodeTitle]);

  const visData: VisData = useMemo(() => ({ table: data?.results || [] }), [data?.results]);

  return { spec, data: visData, error, dataTrimmed: dataTrimmed || false };
}

export default useVegaSpecInner;
