import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { FiAlertTriangle, FiPlus, FiTrash2 } from 'react-icons/fi';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Code,
  ExpandedIndex,
  Flex,
  Icon,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { colors } from 'styles/colors';

import useUpdateNodes from 'hooks/api/useUpdateNodes';
import { GraphNode, UnusedParameter } from 'types';
import { ParameterType } from 'types/api';
import { delay } from 'utils';
import { isWebhookNodeType } from 'utils/nodes';
import PanelSection from 'views/Graph/modules/NodePanel/PanelSection';

import AddWebhookParameterModal from './AddWebhookParameterModal';
import ParameterForm from './ParameterForm';

type ParametersProps = {
  node: GraphNode;
  isConnection?: boolean;
};

const Tag = ({ children }: { children: ReactNode }) => (
  <Code
    fontSize="xs"
    textAlign="left"
    whiteSpace="nowrap"
    fontWeight={600}
    paddingY={1}
    paddingX={2}
    color="text2"
    overflow="hidden"
    textOverflow="ellipsis"
    borderRadius="md"
  >
    {children}
  </Code>
);

const connectionRegex = /^\s*\$\{\{\s*connection\.([a-zA-Z0-9_]+)\s*}}\s*$/;
const secretRegex = /^\$\{\{\s*([a-zA-Z0-9_]+)\s*}}$/;

export function isConnection(value?: any) {
  if (!value) return null;
  if (typeof value !== 'string') return null;
  return value.match(connectionRegex);
}

export function isSecret(value?: any) {
  if (!value) return null;
  if (typeof value !== 'string') return null;
  return value.match(secretRegex);
}

const ParameterValue = ({ value, defaultValue }: { value: any; defaultValue?: any }) => {
  const isSecretMatch = useMemo(() => isSecret(value), [value]);
  const isConnectionMatch = useMemo(() => isConnection(value), [value]);

  if (isSecretMatch) {
    return <Tag>{isSecretMatch[1]}</Tag>;
  } else if (isConnectionMatch) {
    return <Tag>{isConnectionMatch[1]}</Tag>;
  }

  const normalizedValue = value || defaultValue;
  const presentedValue =
    !!normalizedValue || typeof normalizedValue === 'boolean' ? String(normalizedValue) : '-';

  return (
    <Text
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      color={!!value ? colors.gray[900] : colors.gray[500]}
    >
      {presentedValue}
    </Text>
  );
};

function RemoveParameterButton({
  node,
  parameter,
}: {
  node: GraphNode;
  parameter: UnusedParameter;
}) {
  const { mutate: updateNodes, isLoading } = useUpdateNodes();

  const onRemoveParameter = useCallback(
    async (oldParam: UnusedParameter) => {
      node?.id && updateNodes({ [node.id]: { parameter_values: { [oldParam.name]: null } } });
    },
    [node.id, updateNodes]
  );

  if (isLoading) {
    return <Spinner size={'sm'} />;
  }

  return (
    <Tooltip label="Remove value" fontSize="sm" shouldWrapChildren>
      <Icon
        as={FiTrash2}
        cursor="pointer"
        w={'auto'}
        h={'11px'}
        onClick={() => {
          onRemoveParameter(parameter);
        }}
      />
    </Tooltip>
  );
}

function Parameters({ node, isConnection }: ParametersProps) {
  const inSubNode = !!node.data.parentNodeId;
  const [expandedIndex, setExpandedIndex] = useState<ExpandedIndex>(-1);

  const [showAddParameter, setShowAddParameter] = useState(false);

  // if we're in a component or subnode, then show resolved parameters, otherwise show parameters
  const parameters = (inSubNode ? node.data.resolvedParameters : node.data.parameters).filter(
    (parameter) =>
      isConnection
        ? parameter.parameter_type === 'connection'
        : parameter.parameter_type !== 'connection'
  );

  const isWebhook = isWebhookNodeType(node.data?.type);

  if (parameters.length <= 0) return <></>;

  return (
    <PanelSection
      title={
        <Flex alignItems="center">
          {isConnection ? 'Connections ' : 'Parameters '}
          {isWebhook && (
            <Button py={0} ml="auto" onClick={() => setShowAddParameter(true)}>
              <FiPlus />
              Add Parameter
            </Button>
          )}
        </Flex>
      }
    >
      <Flex
        flex={1}
        flexDir="column"
        gap="2px"
        sx={{
          '.chakra-collapse': {
            // This is a hack to override inline chakra styles
            overflow: 'visible !important',
          },
        }}
      >
        {[...parameters, ...node.data.unused_parameter_values].length === 0 ? (
          <Flex justifyContent={'center'}>no parameters defined</Flex>
        ) : (
          <Accordion allowToggle onChange={setExpandedIndex} index={expandedIndex}>
            {parameters.map((parameter, index) => {
              return (
                <AccordionItem
                  key={parameter.name}
                  border={index === expandedIndex ? `1px solid ${colors.light.border3Focus}` : ''}
                >
                  <AccordionButton>
                    <Flex flex={1} justifyContent="space-between" minW={0} gap={4}>
                      <Flex
                        flex={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="5px"
                        minW={0}
                      >
                        <Tag>{parameter.name}</Tag>
                        {parameter.value === null && parameter.required && (
                          <Tooltip label={`${parameter.name} is required`} placement="top">
                            <Flex>
                              <FiAlertTriangle
                                color={colors.yellow[700]}
                                width="auto"
                                height="12px"
                              />
                            </Flex>
                          </Tooltip>
                        )}
                      </Flex>
                      <Flex flex={1} justifyContent="flex-start" alignItems="center" minW={0}>
                        <Tooltip label={parameter.description} placement="top">
                          <Code
                            display="block"
                            fontSize="xs"
                            bg="none"
                            color={colors.gray[600]}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {parameter.description || 'no description'}
                          </Code>
                        </Tooltip>
                      </Flex>
                      <Flex flex={1} justifyContent="center" alignItems="center" minW={0}>
                        <ParameterValue value={parameter.value} defaultValue={parameter.default} />
                      </Flex>
                    </Flex>
                    <AccordionIcon />
                    {isWebhook && (
                      <Flex ml={3}>
                        <RemoveParameterButton
                          node={node}
                          parameter={{ name: parameter.name, value: parameter.value }}
                        />
                      </Flex>
                    )}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ParameterForm
                      parameter={parameter}
                      nodeId={node.id}
                      onSubmit={async () => {
                        // Artificial delay because it feels unnatural to close parameter immediately after save
                        await delay(500);
                        setExpandedIndex(-1);
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
            {!isConnection &&
              !isWebhook &&
              node.data.unused_parameter_values.map((unusedParameter) => {
                return (
                  <AccordionItem key={unusedParameter.name}>
                    <AccordionButton>
                      <Flex flex={1} justifyContent="space-between" minW={0}>
                        <Flex
                          flex={2}
                          justifyContent="flex-start"
                          alignItems="center"
                          gap="5px"
                          minW={0}
                        >
                          <Tag>{unusedParameter.name}</Tag>

                          <Tooltip
                            label={`Unused value: this parameter is not exposed on node ${node.data.name}`}
                            fontSize="sm"
                            shouldWrapChildren
                          >
                            <FiAlertTriangle
                              stroke={colors.yellow[700]}
                              width="auto"
                              height="12px"
                            />
                          </Tooltip>
                        </Flex>
                        <Flex flex={1} justifyContent="center" alignItems="center" minW={0}>
                          <ParameterValue value={unusedParameter.value} />
                        </Flex>
                      </Flex>
                      <RemoveParameterButton node={node} parameter={unusedParameter} />
                    </AccordionButton>
                  </AccordionItem>
                );
              })}

            {isWebhook &&
              node.data.unused_parameter_values.map((parameter, index) => {
                return (
                  <AccordionItem
                    key={parameter.name}
                    border={index === expandedIndex ? `1px solid ${colors.light.border3Focus}` : ''}
                  >
                    <AccordionButton>
                      <Flex flex={1} justifyContent="space-between" minW={0}>
                        <Flex
                          flex={1}
                          justifyContent="flex-start"
                          alignItems="center"
                          gap="5px"
                          minW={0}
                        >
                          <Tag>{parameter.name}</Tag>
                        </Flex>
                        <Flex flex={1} justifyContent="center" alignItems="center" minW={0}>
                          <ParameterValue value={parameter.value} />
                        </Flex>
                      </Flex>
                      <AccordionIcon />
                      {isWebhook && (
                        <Flex
                          ml={4}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <RemoveParameterButton
                            node={node}
                            parameter={{ name: parameter.name, value: parameter.value }}
                          />
                        </Flex>
                      )}
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <ParameterForm
                        parameter={{ ...parameter, parameter_type: ParameterType.Text }}
                        nodeId={node.id}
                        onSubmit={async () => {
                          // Artificial delay because it feels unnatural to close parameter immediately after save
                          await delay(500);
                          setExpandedIndex(-1);
                        }}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
          </Accordion>
        )}
      </Flex>
      <AddWebhookParameterModal
        isOpen={showAddParameter}
        closeModal={() => setShowAddParameter(false)}
        nodeId={node.id}
      />
    </PanelSection>
  );
}

export default Parameters;
