import { CopyIcon } from '@chakra-ui/icons';
import { Flex, Tooltip } from '@chakra-ui/react';
import { JsonViewer } from '@textea/json-viewer';
import { sourceCode } from 'styles/fonts';

import { copyToClipboard, parseBigIntJSON } from 'utils';

type JsonPreviewProps = {
  value: string;
};

const JsonPreview = ({ value }: JsonPreviewProps) => {
  return (
    <Flex>
      <JsonViewer
        rootName={false}
        className={sourceCode.className}
        value={parseBigIntJSON(value)}
        displayDataTypes={false}
        displayObjectSize={true}
        indentWidth={4}
        enableClipboard={false}
        style={{
          backgroundColor: 'bg3',
          userSelect: 'auto',
          contentVisibility: 'visible',
          minWidth: '130px',
          fontSize: 12,
        }}
      />
      <Tooltip label="copy" placement="top">
        <CopyIcon
          aria-label="copy"
          color="gray.700"
          _hover={{ color: 'black' }}
          _active={{ color: 'gray.500' }}
          cursor="pointer"
          onClick={() => copyToClipboard(value)}
        />
      </Tooltip>
    </Flex>
  );
};

export default JsonPreview;
