import { useEffect, useMemo, useState } from 'react';
import { useMonaco } from '@monaco-editor/react';
import { editor, IDisposable } from 'monaco-editor';
import shallow from 'zustand/shallow';

import { codeKey, createModel, getModel } from 'views/Graph/modules/CodeEditor/utils';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

function useNodeModel(
  nodeId?: string | null,
  filePath?: string | null
): {
  data: {
    code?: string;
    model?: editor.ITextModel | null;
  };
  isLoading: boolean;
  isError: boolean;
} {
  const monaco = useMonaco();
  const [model, setModel] = useState<editor.ITextModel | null>(null);
  const [code, setCode] = useState('');

  const [parsedGraphVersionUID, updatedGraphVersionUIDs] = useStore(
    (state) => [state.parsedGraphVersionUID, state.updatedGraphVersionUIDs],
    shallow
  );

  const {
    data: graph,
    isLoading,
    isError,
  } = useGraph({
    select: (data) => {
      const { uid, graph_files } = data;
      return { uid, graph_files };
    },
  });

  const serverCode = useMemo(() => {
    if (graph?.graph_files && filePath && filePath in graph.graph_files) {
      return graph.graph_files[filePath];
    } else {
      return undefined;
    }
  }, [graph?.graph_files, filePath]);

  useEffect(() => {
    if (model && nodeId === 'graph.yml' && serverCode && model?.getValue() !== serverCode) {
      model?.setValue(serverCode);
    }
  }, [model, serverCode, nodeId]);

  useEffect(() => {
    if (
      model &&
      serverCode &&
      model?.getValue() !== serverCode &&
      parsedGraphVersionUID &&
      !updatedGraphVersionUIDs.has(parsedGraphVersionUID)
    ) {
      model?.setValue(serverCode);
    }
  }, [model, serverCode, nodeId, parsedGraphVersionUID, updatedGraphVersionUIDs]);

  useEffect(() => {
    let disposableHandle: IDisposable | undefined;

    const modelKey = nodeId || filePath;

    if (monaco && graph?.uid && modelKey) {
      const key = codeKey(graph.uid, modelKey);
      let model = getModel(monaco, key);
      if (!model && serverCode !== undefined) {
        // Initialize a model is one does not exist
        model = createModel(monaco, key, serverCode);
      }
      if (!model) {
        return;
      }

      setModel(model);
      setCode(model.getValue());

      if (model) {
        disposableHandle = model.onDidChangeContent(() => setCode(model!.getValue()));
      }
    }

    return () => disposableHandle?.dispose();
  }, [graph?.uid, nodeId, monaco, serverCode, filePath]);

  return { data: { code, model }, isError, isLoading };
}

export default useNodeModel;
