import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChakraProps,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Tooltip,
  useToast,
} from '@chakra-ui/react';

import { useGraphAddedNodeId } from 'hooks/api/useGraph';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import { NodeData } from 'types';
import { isChartNodeType, isMarkdownNodeType, isStoreNodeType } from 'utils/nodes';

import EditableControls from './EditableControls';

type NodeNameProps = ChakraProps & {
  nodeId?: string;
  isEditable: boolean;
  nodeData: NodeData;
};

function nameIsValidForNodeType(name: string, type: NodeData['type']) {
  if (isStoreNodeType(type)) {
    return new RegExp('^[a-zA-Z0-9_]+$').test(name);
  }

  return true;
}

function EditableNodeName({ nodeId, nodeData, isEditable = true }: NodeNameProps) {
  const [nodeName, setNodeName] = useState(nodeData.name);
  const { mutate: updateNodes } = useUpdateNodes();
  const toast = useToast();

  const addedNodeId = useGraphAddedNodeId();

  const hasValidationError = !nameIsValidForNodeType(nodeName, nodeData.type);

  const recentlyAddedChartOrMarkdown = useMemo(() => {
    // only auto show title for charts and markdown nodes (since other node titles are shown in the graph)
    return (
      addedNodeId === nodeId &&
      (isChartNodeType(nodeData.type) || isMarkdownNodeType(nodeData.type))
    );
  }, [addedNodeId, nodeData.type, nodeId]);

  const onSubmitName = useCallback(
    (title: string) => {
      if (nodeId) {
        if (!nameIsValidForNodeType(title, nodeData.type)) {
          toast({
            description:
              'Invalid table name. Table names can only contain letters, numbers, and underscores.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setNodeName(nodeData.name);
          return false;
        }
        if (isStoreNodeType(nodeData.type)) {
          // if this is a store node, then don't update title, update store_name
          updateNodes({ [nodeId]: { store_name: title } });
        } else {
          updateNodes({ [nodeId]: { title } });
        }
      }
    },
    [nodeId, nodeData.type, nodeData.name, toast, updateNodes]
  );

  useEffect(() => {
    setNodeName(nodeData.name);
  }, [nodeData.name]);

  if (!nodeData || !nodeId) {
    return null;
  }

  const handleKeyDown = (e: any) => {
    if (e.code === 'Enter' && hasValidationError) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <Editable
      isDisabled={!isEditable}
      value={nodeName}
      onSubmit={onSubmitName}
      onChange={setNodeName}
      startWithEditView={hasValidationError || recentlyAddedChartOrMarkdown}
    >
      <Flex alignItems="center" wordBreak="break-all">
        <Tooltip label={nodeName}>
          <EditablePreview fontWeight={600} fontSize={15} noOfLines={1} />
        </Tooltip>
        <EditableInput
          py={1}
          _focus={{
            boxShadow: 'none',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: hasValidationError ? 'nodeError' : 'action',
          }}
          onKeyDown={handleKeyDown}
          maxLength={64}
        />
        {isEditable && <EditableControls submitDisabled={hasValidationError} />}
      </Flex>
    </Editable>
  );
}

export default EditableNodeName;
