import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import shallow from 'zustand/shallow';

import { GraphState } from 'types';
import useStore from 'views/Graph/state';

import useGraph from './api/useGraph';

const selectorSetGraphSaving = (s: GraphState) => s.setGraphSaving;
const selector = (s: GraphState) => ({
  graphSaving: s.graphSaving,
  parsedGraphVersionUID: s.parsedGraphVersionUID,
});

function useSavingMutateOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) {
  const setGraphSaving = useStore(selectorSetGraphSaving);

  return useMemo(
    () => ({
      ...options,
      onMutate: (variables: TVariables) => {
        setGraphSaving(true);
        if (options?.onMutate) {
          return options.onMutate(variables);
        }
      },
      onSettled: (
        data: TData | undefined,
        error: TError | null,
        variables: TVariables,
        context?: TContext
      ) => {
        let result: unknown;
        if (options?.onSettled) {
          result = options.onSettled(data, error, variables, context);
        }
        setIsLoading && setIsLoading(true);
        setGraphSaving(false);
        return result;
      },
    }),
    [setGraphSaving, setIsLoading, options]
  );
}

export type CustomUseMutationResult<TData, TError, TVariables, TContext> = UseMutationResult<
  TData,
  TError,
  TVariables,
  TContext
> & { isGraphSaving: boolean };

// This extends the functionality of useMutation from react-query
// Should be used in place of useMutation for any endpoint that edits the graph
// (mostly post/put/patch requests involving '/graph_versions/{graphVersionUID}')
// It automatically updates the graphSaving state in the store and
// adds a custom isGraphSaving property to the result object for convenience
function useGraphEditMutate<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
): CustomUseMutationResult<TData, TError, TVariables, TContext> {
  const { graphSaving, parsedGraphVersionUID } = useStore(selector, shallow);
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });

  // As soon as the graphUID changes, more graph layout processing is done in the useParseGraphManifest hook.
  // Resolving the mutate operation too early can result in old values to be disaplyed temporarily.
  // Until the graphUID is updated by useParseGraphManifest, keep `isLoading` set to `true`
  const [customIsLoading, setCustomIsLoading] = useState(false);

  const augmentedOptions = useSavingMutateOptions(options, setCustomIsLoading);
  const mutation = useMutation(mutationFn, augmentedOptions) as CustomUseMutationResult<
    TData,
    TError,
    TVariables,
    TContext
  >;

  useEffect(() => {
    if (customIsLoading && parsedGraphVersionUID === graph?.version_uid) {
      setCustomIsLoading(false);
    }
  }, [customIsLoading, parsedGraphVersionUID, graph?.version_uid]);

  mutation.isGraphSaving = graphSaving;

  // use a custom isLoading state to avoid the isLoading state from react-query which changes before the mutation is complete/data is available
  mutation.isLoading = mutation.isLoading || customIsLoading;

  return mutation;
}

export default useGraphEditMutate;
