import { memo, useEffect, useRef, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';

import TableView from 'components/TableView';
import useStoreNodeData, { defaultPageSize } from 'hooks/api/useStoreNodeData';
import usePreventZoom from 'hooks/usePreventZoom';
import { NodeData } from 'types';

function TableNodePanel({
  nodeId,
  nodeData,
  showTitle = false,
}: {
  nodeId: string;
  nodeData: NodeData;
  showTitle?: boolean;
}) {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const { data, isFetching, isLoading } = useStoreNodeData(
    nodeId,
    pageIndex + 1,
    'table',
    pageSize
  );

  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [nodeId, setPagination, pageSize]);

  const ref = useRef<HTMLDivElement>(null);
  usePreventZoom(ref);

  if (!isFetching && !isLoading && data?.count === 0) {
    return (
      <Box p={2} flexGrow={1} fontSize="xs">
        no data available for <strong>{nodeData.name}</strong>
      </Box>
    );
  }

  return (
    <Box width={'100%'} height={'100%'}>
      {showTitle && (
        <Text noOfLines={1} textAlign="center" wordBreak="break-all" fontSize={'xs'} pb={1}>
          <strong>{nodeData.name}</strong>
        </Text>
      )}
      <Box
        width={'100%'}
        height={showTitle ? 'calc(100% - 22px)' : '100%'}
        className="nowheel cancel-open-tab"
        ref={ref}
      >
        <TableView
          id={nodeId}
          w="100%"
          isLoading={isFetching || isLoading}
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPagination={setPagination}
          count={data?.count || null}
          rawData={data?.results}
          footerChildren={null}
          storeNodeId={nodeId}
        />
      </Box>
    </Box>
  );
}

export default memo(TableNodePanel);
