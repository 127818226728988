import { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from '@chakra-ui/react';

import config from 'config';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { Parameter } from 'types';
import { ParameterType } from 'types/api';
import useStore from 'views/Graph/state';

type NodeFormParameterProps = {
  nodeId: string;
  parameter: Parameter;
};

const NodeFormParameter = ({ nodeId, parameter }: NodeFormParameterProps) => {
  const { mutate: updateNodes, isLoading: isSaving } = useUpdateNodes();
  const { readOnly } = useReadOnlyMode();

  const setParameter = useStore((state) => state.setParameter);

  // need to use a ref to use the latest save function since it's in a timeout
  const updateNodesRef = useRef<null | typeof updateNodes>(null);
  const timer = useRef<number>();

  const [parameterValue, setParameterValue] = useState(parameter.value);

  const parameterValueDisplay = useMemo(
    () => parameterValue || parameter.default,
    [parameterValue, parameter.default]
  );

  useEffect(() => setParameterValue(parameter.value), [parameter.value, setParameterValue]);

  useEffect(() => {
    updateNodesRef.current = updateNodes;
  }, [updateNodes]);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (readOnly || !parameter) return;

      let newParameterValue: string | boolean;
      if (parameter.parameter_type === ParameterType.Boolean) {
        newParameterValue = (event as ChangeEvent<HTMLInputElement>).currentTarget.checked;
      } else {
        newParameterValue = event.currentTarget.value;
      }
      setParameter(nodeId, { ...parameter, value: newParameterValue });
      setParameterValue(newParameterValue);

      window.clearTimeout(timer.current);
      // Autosaves parameter after configured timeout
      timer.current = window.setTimeout(() => {
        updateNodesRef?.current &&
          updateNodesRef.current({
            [nodeId]: { parameter_values: { [parameter.name]: newParameterValue } },
          });
      }, config.ideSaveTimeout);
    },
    [nodeId, parameter, readOnly, setParameter]
  );

  if (!parameter) return <></>;

  const inputClass = 'cancel-grid-drag cancel-open-tab';

  function renderInput() {
    if (!parameter) return <></>;
    switch (parameter.parameter_type) {
      case ParameterType.Text:
        return (
          <Textarea
            value={parameterValueDisplay}
            disabled={isSaving || readOnly}
            rows={1}
            minH="40px"
            className={inputClass}
            onChange={handleChange}
          />
        );
      case ParameterType.Float:
        return (
          <Input
            value={parameterValueDisplay}
            type="number"
            step="any"
            disabled={isSaving || readOnly}
            className={inputClass}
            onChange={handleChange}
          />
        );
      case ParameterType.Integer:
        return (
          <Input
            value={parameterValueDisplay}
            type="number"
            step="1"
            disabled={isSaving || readOnly}
            className={inputClass}
            onChange={handleChange}
          />
        );
      // TODO figure out why Boolean is not working
      case ParameterType.Boolean:
        return (
          <Switch
            isChecked={parameterValueDisplay}
            disabled={isSaving || readOnly}
            className={inputClass}
            onChange={handleChange}
          />
        );
      case ParameterType.Date:
        return (
          <Input
            value={parameterValueDisplay}
            type="date"
            disabled={isSaving || readOnly}
            className={inputClass}
            onChange={handleChange}
          />
        );
      case ParameterType.DateTime:
        return (
          <Input
            value={parameterValueDisplay}
            type="datetime-local"
            disabled={isSaving || readOnly}
            className={inputClass}
            onChange={handleChange}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <Flex flexDirection="column" gap={3}>
      <FormControl
        id="value"
        isRequired={parameter.parameter_type === ParameterType.Boolean ? false : parameter.required}
      >
        <FormLabel>{parameter.name}</FormLabel>
        <FormHelperText>{parameter.description}</FormHelperText>
        {renderInput()}
      </FormControl>
    </Flex>
  );
};

export default memo(NodeFormParameter);
