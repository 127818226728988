import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useDuplicateNodeURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

function useDuplicateNode(options?: UseMutationOptions<Graph, unknown, string>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const url = useDuplicateNodeURL(graph?.version_uid);

  return useGraphEditMutate(async (nodeId) => {
    const res = await Axios.post<Graph>(url, { node_ids: [nodeId] });

    if (res.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(res.data.version_uid);
    }

    return res?.data;
  }, options);
}

export default useDuplicateNode;
