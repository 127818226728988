import { useCallback } from 'react';

import useStore from 'views/Graph/state';

function useStoreNode(id: string | null) {
  const node = useStore(useCallback((s) => s.nodes.find((n) => n.id === id), [id]));

  return node;
}

export default useStoreNode;
