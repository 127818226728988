import { useCallback } from 'react';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { GraphState } from 'types';
import { GraphStatus, NodeExecution } from 'types/api';
import { useLatestExecutionURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphStatusQueryKey, NodeExecutionsQueryKey } from 'utils/queryKeys';
import useStore from 'views/Graph/state';

const selector = (s: GraphState) => s.setGraphStatus;

function useInvalidateNodeExecutions() {
  const queryClient = useQueryClient();
  const { mutateAsync: getLatestExecution } = useLatestExecution();
  const setGraphStatus = useStore(selector);

  return useCallback(
    async (nodeID: string, execution: NodeExecution | null) => {
      // check if node is inside a component. If it is, don't invalidate the node
      const { nodes } = useStore.getState();
      const node = nodes.find((node) => node.id === nodeID);
      if (!node) {
        return;
      }

      queryClient.invalidateQueries(NodeExecutionsQueryKey(nodeID));
      try {
        execution = execution || (await getLatestExecution(nodeID));
        // update execution for this node in the GraphStatus
        queryClient.setQueryData(GraphStatusQueryKey(), (data: any) => {
          data = data as GraphStatus;
          const newData = {
            ...data,
            executions: {
              ...data.executions,
              [nodeID]: execution,
            },
          };
          setGraphStatus(newData);
          return newData;
        });
      } catch (e) {
        console.error('useInvalidateNodeExecutions error', e);
      }
    },
    [queryClient, getLatestExecution, setGraphStatus]
  );
}

export default useInvalidateNodeExecutions;

function useLatestExecution(
  options?: UseMutationOptions<NodeExecution, unknown, string>
): UseMutationResult<NodeExecution, unknown, string, unknown> {
  const url = useLatestExecutionURL();
  const mutation = useMutation(async (nodeID: string) => {
    const res = await Axios.get<NodeExecution>(url! + nodeID);
    return res?.data;
  }, options);
  return mutation;
}
