import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { StoreData } from 'types/api';
import { useStoreNodeDataURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StoreNodeDataQueryKey } from 'utils/queryKeys';

import { useGraphUid } from './useGraph';

export const defaultPageSize = 200;

function makeQueryKey(nodeId: string | null, page: number, pageSize: number) {
  return [...(StoreNodeDataQueryKey(nodeId) as Array<any>), page, pageSize];
}

function useStoreNodeData(
  nodeId: string | null,
  page = 1,
  storeType: string = 'table',
  pageSize: number = defaultPageSize,
  prefetch: boolean = true,
  graphUID?: string | null
) {
  const uid = useGraphUid();

  const url = useStoreNodeDataURL(graphUID || uid);
  const queryKey = makeQueryKey(nodeId, page, pageSize);
  const queryClient = useQueryClient();

  const [count, setCount] = useState<number | null>(null);

  const fetchStoreData = useCallback(
    async (currentPage: number) => {
      const res = await Axios.get<StoreData>(url!, {
        params: {
          node_id: nodeId,
          store_type: storeType,
          page: currentPage,
          page_size: pageSize,
        },
      });
      if (res.data.count !== count) {
        setCount(res.data.count);
      }
      return res?.data;
    },
    [count, url, nodeId, pageSize, storeType]
  );

  const enabled = !!url && !!nodeId;

  useEffect(() => {
    if (!enabled || !prefetch) {
      return;
    }
    const hasNextPage = count === null ? false : page * pageSize < count;
    const newQueryKey = makeQueryKey(nodeId, page + 1, pageSize);
    if (hasNextPage && !queryClient.getQueryData(newQueryKey)) {
      queryClient.prefetchQuery(newQueryKey, () => fetchStoreData(page + 1));
    }
  }, [page, count, fetchStoreData, nodeId, queryClient, pageSize, enabled, prefetch]);

  const result = useQuery<StoreData>(queryKey, () => fetchStoreData(page), {
    enabled: enabled,
  });

  return result;
}

export default useStoreNodeData;
