import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { ButtonGroup, ChakraProps, Flex, IconButton, useEditableControls } from '@chakra-ui/react';

type EditableControlsProps = ChakraProps & {
  isSubmitting?: boolean;
  submitDisabled?: boolean;
  showLoadingSpinner?: boolean;
};

function EditableControls({
  isSubmitting = false,
  submitDisabled = false,
  showLoadingSpinner = false,
  ...rest
}: EditableControlsProps) {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } =
    useEditableControls();

  const buttonProps = showLoadingSpinner ? { isLoading: isSubmitting } : {};

  return isEditing ? (
    <ButtonGroup justifyContent="center" ml={2} {...rest}>
      <IconButton
        variant="ghost"
        colorScheme="gray"
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
        aria-label="submit"
        disabled={submitDisabled}
      />
      <IconButton
        variant="ghost"
        colorScheme="gray"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
        aria-label="cancel"
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center" {...rest}>
      <IconButton
        variant="ghost"
        colorScheme="lightgray"
        icon={<EditIcon />}
        {...getEditButtonProps()}
        aria-label="edit"
        isDisabled={isSubmitting}
        {...buttonProps}
      />
    </Flex>
  );
}

export default EditableControls;
