import { memo, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Flex } from '@chakra-ui/react';

import useTriggerGraph from 'hooks/api/useTriggerGraph';
import { NodeData } from 'types';
import { ParameterType } from 'types/api';
import { isSecret } from 'views/Graph/modules/NodePanel/Parameters/Parameters';

import NodeFormParameter from './NodeFormParameter';

type NodeFormFunctionProps = {
  id: string;
  data: NodeData;
};

const NodeFormFunction = ({ id, data }: NodeFormFunctionProps) => {
  const parameters = useMemo(() => {
    const inSubNode = !!data.parentNodeId;
    const parameters = inSubNode ? data.resolvedParameters : data.parameters;
    return parameters.filter((parameter) => {
      if (parameter.parameter_type === ParameterType.Text && isSecret(parameter.value)) {
        return false;
      }

      return [
        ParameterType.Text,
        ParameterType.Integer,
        ParameterType.Float,
        ParameterType.Date,
        ParameterType.DateTime,
      ].includes(parameter.parameter_type);
    });
  }, [data.parameters, data.parentNodeId, data.resolvedParameters]);

  const { mutate: trigger } = useTriggerGraph(id);

  const hotKeyTriggerRef = useHotkeys<HTMLDivElement>(
    'meta+enter',
    () => trigger(),
    { enableOnFormTags: true },
    [trigger]
  );

  return (
    <Flex flexDirection="column" flex={1} padding={2} gap={2} ref={hotKeyTriggerRef}>
      {parameters.map((parameter, index) => {
        return <NodeFormParameter key={index} parameter={parameter} nodeId={id} />;
      })}
      {(!parameters || parameters.length === 0) && (
        <Flex p={2}>No parameters available to configure</Flex>
      )}
    </Flex>
  );
};

export default memo(NodeFormFunction);
