import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { ConnectionPaginated } from 'types/api';
import { useConnectionsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (state: AppState) => state.organizationUID;

function useConnections(page = 1, options?: UseQueryOptions<ConnectionPaginated>) {
  const organizationUID = useStore(selector);

  const url = useConnectionsURL(organizationUID);

  return useQuery<ConnectionPaginated>(
    ['connections', organizationUID, page],
    async () => {
      const res = await Axios.get<ConnectionPaginated>(url, {
        params: { page_size: 100, page },
      });

      return res.data;
    },
    {
      enabled: !!organizationUID,
      ...options,
    }
  );
}

export default useConnections;
