import { ReactNode } from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';

type PanelSectionProps = ChakraProps & {
  title: ReactNode;
  children: ReactNode;
};

function PanelSection({ title, children, ...rest }: PanelSectionProps) {
  return (
    <Box mb={4} {...rest}>
      <Box fontWeight={500} my={1} fontSize={'xs'}>
        {title}
      </Box>
      <Box textStyle="label">{children}</Box>
    </Box>
  );
}

export default PanelSection;
