import { useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { Parameter } from 'types';
import useConnections from 'views/Connections/hooks/useConnections';

type SelectConnectionProps = {
  type: string;
  isLoading?: boolean;
  onChange?: (newValue: string | undefined) => void;
};

const SelectConnection = (props: SelectConnectionProps & UseControllerProps<Parameter>) => {
  const { type, isLoading = false, onChange } = props;
  const { data: connections } = useConnections();
  const { readOnly } = useReadOnlyMode();
  const { field } = useController(props);

  const options = useMemo(() => {
    if (!connections) return [];
    return connections.results
      .filter((connection) => connection.connection_type === type)
      .map((connection) => ({
        label: connection.name,
        value: `\${{ connection.${connection.name} }}`,
      }));
  }, [connections, type]);

  const allowSelect = !readOnly && !!options?.length;
  const value = options.find((option) => option.value.indexOf(field.value) > -1);

  const missingConnectionName = !value && field.value;

  return (
    <>
      <Select
        {...field}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        placeholder="Connection..."
        closeMenuOnSelect={true}
        size="sm"
        onChange={(newValue) => {
          onChange?.(newValue?.value);
        }}
        isReadOnly={!allowSelect}
        value={value}
      />
      {!readOnly && missingConnectionName && (
        <Text color="light.nodeError">
          Your connection no longer exists. Please select or create a new one
        </Text>
      )}
    </>
  );
};

export default SelectConnection;
