import { NodeData } from 'types';
import { getIconForNodeType } from 'utils/nodes';
import DarkTextWrapper from 'views/Graph/modules/GraphView/NodeTypes/components/DarkTextWrapper';

type NodeIconProps = {
  nodeData: NodeData;
};

const NodeIcon = ({ nodeData }: NodeIconProps) => {
  if (!nodeData) return <></>;
  if (['python', 'sql'].includes(nodeData.type)) {
    return <DarkTextWrapper isIcon={true}>{nodeData.type[0].toUpperCase()}</DarkTextWrapper>;
  } else {
    const IconComponent = getIconForNodeType(nodeData);
    return <IconComponent width="24px" height="100%" />;
  }
};

export default NodeIcon;
