import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { colors } from 'styles/colors';
import { Data, Renderers } from 'vega';
import embed, { Result, vega, VisualizationSpec } from 'vega-embed';

type VegaChartProps = {
  data: Data[];
  spec: VisualizationSpec;
  renderer?: Renderers;
  onError?: (error: Error) => void;
  style?: CSSProperties;
  className?: string;
  disableTooltips?: boolean;
  dataTrimmed?: boolean;
};

const VegaChart = ({
  data,
  spec,
  renderer = 'canvas',
  onError,
  style,
  className,
  disableTooltips = false,
  dataTrimmed = false,
}: VegaChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<Result>();

  const customSpec = useMemo(() => {
    const dataTrimmerSubtitle = {
      subtitle: 'Chart data is limited to 2000 records',
      subtitleColor: colors.yellow[800],
    };

    if (dataTrimmed) {
      if (typeof spec.title === 'string') {
        return {
          ...spec,
          title: {
            text: spec.title,
            ...dataTrimmerSubtitle,
          },
        };
      } else if (typeof spec.title === 'object') {
        return {
          ...spec,
          title: {
            ...spec.title,
            ...dataTrimmerSubtitle,
          },
        };
      } else {
        return spec;
      }
    } else {
      return spec;
    }
  }, [dataTrimmed, spec]);

  const setData = useCallback((tableData: any[]) => {
    if (viewRef.current) {
      viewRef.current.view
        .change(
          'table',
          vega
            .changeset()
            .remove(() => true)
            .insert(tableData)
        )
        .resize()
        .run();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (chartRef.current !== null && data.length > 0 && customSpec?.$schema) {
        try {
          viewRef.current = await embed(chartRef.current, customSpec as VisualizationSpec, {
            mode: 'vega-lite',
            renderer,
            actions: false,
            tooltip: !disableTooltips,
          });
          setData(data);
        } catch (error) {
          console.error(error);
          onError?.(error as Error);
        }
      }
    })();

    return () => {
      viewRef.current?.finalize();
    };
  }, [chartRef, setData, customSpec, data, renderer, onError, disableTooltips, spec]);

  return (
    <div ref={chartRef} className={className} style={{ ...style, width: '100%', height: '100%' }} />
  );
};

export default memo(VegaChart);
