import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { StoreMetadata } from 'types/api';
import { useStoreMetadataURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StoreNodeMetadataQueryKey } from 'utils/queryKeys';

const defaultOptions: UseQueryOptions<StoreMetadata> = {
  staleTime: 60 * 1000,
};

function useStoreMetadata(
  nodeId: string,
  options: UseQueryOptions<StoreMetadata> = defaultOptions
) {
  const url = useStoreMetadataURL();
  const queryKey = StoreNodeMetadataQueryKey(nodeId);

  const result = useQuery<StoreMetadata>(
    queryKey,
    async () => {
      const res = await Axios.get<StoreMetadata>(url!, {
        params: { node_id: nodeId },
      });
      return res?.data;
    },
    {
      enabled: !!url && !!nodeId,
      ...options,
    }
  );

  return result;
}

export default useStoreMetadata;
