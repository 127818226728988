import { keyframes } from '@chakra-ui/react';

const bounce = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const bounceAnimation = `${bounce} infinite 1500ms ease-out`;

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
`;

export const pulseAnimation = `${pulse} infinite 2000ms ease-out`;

const ping = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const pingAnimation = `${ping} 1s cubic-bezier(0,0,.2,1) infinite`;
