import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { Graph } from 'types/api';
import { useUpdateNodesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

type UseUpdateNode = {
  title?: string;
  description_path?: string | null;
  trigger?: string;
  parameter_values?: Record<string, any>;
  chart_input?: string;
  webhook?: string;
  store_name?: string;
  position_x?: number;
  position_y?: number;
  width?: number;
  height?: number;
  color?: string | null;
  style?: string | null;
  icon_url?: string | null;
  wait_for_response?: boolean;
};

export type UseUpdateNodesType = Record<string, UseUpdateNode>;

function useUpdateNodes(options?: UseMutationOptions<Graph, unknown, UseUpdateNodesType>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const { readOnly } = useReadOnlyMode();
  const url = useUpdateNodesURL(graph?.version_uid);

  return useGraphEditMutate<Graph, unknown, UseUpdateNodesType>(async (params) => {
    if (readOnly) {
      throw new Error('Cannot update node in read only mode');
    }

    try {
      const res = await Axios.patch<Graph>(url, { nodes: params });
      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }
      return res?.data;
    } catch (e: any) {
      e.message = `Node ${Object.keys(params)} couldn't be updated.${
        e.response?.data?.detail && ` ${e.response.data.detail}`
      } - ${JSON.stringify(params)}`;
      console.error(e.response);
      throw new Error(e);
    }
  }, options);
}

export default useUpdateNodes;
