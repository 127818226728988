import { useMemo } from 'react';
import { theme } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import {
  dark,
  excel,
  fivethirtyeight,
  ggplot2,
  googlecharts,
  latimes,
  powerbi,
  quartz,
  urbaninstitute,
  vox,
} from 'vega-themes';

import useStore from 'views/Graph/state';

function useVegaTheme() {
  const vegaTheme = useStore((state) => state.vegaTheme);

  const config = useMemo(() => {
    switch (vegaTheme) {
      case 'dark':
        return dark;
      case 'excel':
        return excel;
      case 'fivethirtyeight':
        return fivethirtyeight;
      case 'ggplot2':
        return ggplot2;
      case 'googlecharts':
        return googlecharts;
      case 'latimes':
        return latimes;
      case 'quartz':
        return quartz;
      case 'urbaninstitute':
        return urbaninstitute;
      case 'vox':
        return vox;
      case 'powerbi':
        return powerbi;
      default:
        return {
          color: {
            blue: colors.blue[500],
            orange: theme.colors.orange[400],
            red: colors.red[400],
            teal: theme.colors.cyan[400],
            green: colors.green[400],
            yellow: colors.yellow[500],
            purple: theme.colors.purple[500],
            pink: theme.colors.pink[400],
            brown: theme.colors.yellow[700],
            gray0: '#000',
            gray1: '#111',
            gray2: '#222',
            gray3: '#333',
            gray4: '#444',
            gray5: '#555',
            gray6: '#666',
            gray7: '#777',
            gray8: '#888',
            gray9: '#999',
            gray10: '#aaa',
            gray11: '#bbb',
            gray12: '#ccc',
            gray13: '#ddd',
            gray14: '#eee',
            gray15: '#fff',
          },
        };
    }
  }, [vegaTheme]);

  return config;
}

export default useVegaTheme;
