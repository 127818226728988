import { memo, useMemo, useRef } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Cell } from '@tanstack/react-table';
import Linkify from 'linkify-react';
import { sourceCode } from 'styles/fonts';

import useFocusLost from 'hooks/useFocusLost';
import { FieldType } from 'types/api';
import { copyToClipboard, isJSON } from 'utils';

import JsonPreview from './JsonPreview';

const js = require('react-syntax-highlighter/dist/cjs/languages/prism/javascript').default;

SyntaxHighlighter.registerLanguage('javascript', js);

const monospaceStyle: TextProps = {
  className: sourceCode.className,
};
const STRING_STYLE: TextProps = {};
const NUMBER_STYLE: TextProps = { textAlign: 'right', ...monospaceStyle };
const BOOLEAN_STYLE: TextProps = { color: '#4C6EF5', ...monospaceStyle };
const UNDEFINED_STYLE: TextProps = { color: '#92400e' };
const NULL_STYLE: TextProps = { color: '#808080', ...monospaceStyle };

function getRenderValue(value: any, fieldType: string | null, header: string) {
  if (value === null) {
    return 'null';
  }
  if (typeof value === 'undefined') {
    return 'undefined';
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  if (fieldType) {
    if (
      ['Integer', 'Float', 'Decimal'].includes(fieldType) &&
      header !== 'id' &&
      !header.endsWith('_id')
    ) {
      return new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 6,
      }).format(value);
    } else if (['Text', 'LongText'].includes(fieldType)) {
      return value;
    } else if ('Boolean' === fieldType) {
      return value ? 'true' : 'false';
    }
  }
  return value;
}

function getPopoverValue(value: any, fieldType: string | null) {
  if (value === null) {
    return 'null';
  }
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  if (fieldType) {
    if (['Integer', 'Float', 'Decimal'].includes(fieldType)) {
      return value;
    } else if (['Text', 'LongText'].includes(fieldType)) {
      return value;
    } else if ('Boolean' === fieldType) {
      return value ? 'true' : 'false';
    }
  }
  return value;
}

function getRenderStyle(value: any, fieldType: string | null): TextProps {
  if (value === null) {
    return NULL_STYLE;
  }
  if (typeof value === 'undefined') {
    return UNDEFINED_STYLE;
  }

  if (fieldType) {
    if (['Integer', 'Float', 'Decimal'].includes(fieldType)) {
      return NUMBER_STYLE;
    } else if (['Text', 'LongText'].includes(fieldType)) {
      return STRING_STYLE;
    } else if ('Boolean' === fieldType) {
      return BOOLEAN_STYLE;
    }
  }
  return {};
}

type CellProps = {
  value: any;
  isSelected: boolean;
  type: string | FieldType | null;
  header: string;
  setSelectedCell: (selectedCell?: Cell<any, unknown>) => void;
};

type PopoverCell = Omit<CellProps, 'type' | 'header'> & {
  value: any;
  renderValue: any;
  renderStyle: object;
  fieldType: string | null;
};

function PopoverCell({
  value,
  renderValue,
  renderStyle,
  fieldType,
  isSelected,
  setSelectedCell,
}: PopoverCell) {
  const ref = useRef<HTMLDivElement | null>(null);
  useFocusLost(() => isSelected && setSelectedCell(undefined), [ref]);

  return (
    <PopoverBody fontSize="14px" userSelect={'text'} whiteSpace="pre-wrap" tabIndex={-1} ref={ref}>
      {fieldType === 'Json' && isJSON(renderValue) ? (
        <JsonPreview value={renderValue} />
      ) : (
        <Text {...renderStyle}>
          <Linkify options={{ target: '_blank', rel: 'noopener noreferrer nofollow' }}>
            {getPopoverValue(value, fieldType)}
          </Linkify>
        </Text>
      )}
    </PopoverBody>
  );
}

function Cell({ value, isSelected, type, header, setSelectedCell }: CellProps) {
  const fieldType = !!type ? (typeof type === 'string' ? type : type.name) : null;

  const renderValue = useMemo(() => {
    return getRenderValue(value, fieldType, header);
  }, [value, fieldType, header]);

  const renderStyle = useMemo<TextProps>(() => {
    return getRenderStyle(value, fieldType);
  }, [value, fieldType]);

  const cellValue = useMemo(() => {
    return (
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        onCopy={() => {
          isSelected && copyToClipboard(value as string);
        }}
        {...renderStyle}
      >
        {renderValue}
      </Text>
    );
  }, [renderStyle, renderValue, isSelected, value]);

  if (!isSelected) {
    return cellValue;
  }

  return (
    <Box>
      <Popover placement="right-start" isOpen={isSelected} autoFocus={false} closeOnBlur>
        <PopoverTrigger>{cellValue}</PopoverTrigger>
        <Portal>
          <PopoverContent
            className="cancel-grid-drag"
            color="#000000"
            bg="#ffffff"
            borderColor="border2"
            w="fit-content"
            maxW="400px"
            maxH="600px"
            overflowY="auto"
            overflowX="hidden"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <PopoverArrow />
            <PopoverCell
              {...{ value, renderValue, renderStyle, isSelected, fieldType, setSelectedCell }}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
}

export default memo(Cell);
