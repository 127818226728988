import { memo, useEffect, useRef, useState } from 'react';

import { Label } from 'components/Typography';

type CounterProps = {
  startTime?: string | null;
  preText?: string;
  postText?: string;
};

function Counter({ startTime, preText = '', postText = ' seconds' }: CounterProps) {
  const timer = useRef(0);
  const [counterLabel, setCounterLabel] = useState('');

  useEffect(() => {
    if (!startTime) return;
    let start: number = new Date(startTime).getTime();
    let prevTime: number | null = null;

    const countUp = () => {
      const now = Date.now();
      prevTime = start || now;
      const milliSeconds = ~~Math.floor(now - prevTime);
      const counterLabel = milliSeconds / 1000;

      setCounterLabel(counterLabel.toFixed(1));
      timer.current = window.requestAnimationFrame(countUp);
    };

    timer.current = window.requestAnimationFrame(countUp);

    return () => {
      window.cancelAnimationFrame(timer.current);
    };
  }, [startTime]);

  return (
    <Label textAlign="right">
      {preText} <span style={{ width: '35px', display: 'inline-block' }}>{counterLabel}</span>{' '}
      {postText}
    </Label>
  );
}

export default memo(Counter);
